'use client';
import { cn } from '@/utils/cn';
import { useEffect, useState } from 'react';

interface ContentLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  header?: React.ReactNode;
}

const ContentLayout: React.FC<ContentLayoutProps> = ({
  children,
  header,
  className,
  ...props
}) => {
  return (
    <div className="w-[100vw]">
      {/* h-[100vh] overflow-hidden */}
      {/* border-t-2 border-wecopink  */}
      {header}
      <main
        className={cn('', className)} // pt-20 mt-1 // pt-32 with weco shadow
        // h-[calc(100vh - 57px)] overflow-hidden
        {...props}
      >
        {children}
      </main>
    </div>
  );
};
export default ContentLayout;
