'use client';
import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from '@/utils/cn';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import Link from 'next/link';
import { Skeleton } from './skeleton';

export type ComboBoxItem = {
  name: React.ReactNode;
  id: string;
  route: string;
};

export default function ComboBox({
  items,
  selectedItem,
  icon,
  suffixIcon
}: {
  items: ComboBoxItem[];
  selectedItem?: string | null;
  icon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(selectedItem);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          role="combobox"
          aria-expanded={open}
          size="sm"
          className="min-w-[90px] justify-between" // was "text-m"
        >
          {icon}
          {items.find((item) => item.id === selectedItem)?.name || (
            <Skeleton className="w-12 h-4" />
          )}
          {suffixIcon}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="min-w-[90px] p-0 invstyle drop-shadow-2xl bg-transparent backdrop-blur border-0 border-none w-fit"
        align="start"
      >
        <Command className="invstyle bg-popover/80">
          {/* <CommandInput
            placeholder="Search..."
            className="text-foreground placeholder:text-foreground/70 w-16"
          /> */}
          <CommandList className="invstyle">
            <CommandEmpty>Loading...</CommandEmpty>
            <CommandGroup className="invstyle">
              {items.map((item) => (
                <Link href={item.route} key={item.id}>
                  <CommandItem
                    key={item.id}
                    value={item.id}
                    onSelect={(currentValue) => {
                      setValue(item.id);
                      setOpen(false);
                      // item.onSelect();
                      // const newUrl = item.route;
                      // window.history.replaceState(
                      //   { ...window.history.state, as: newUrl, url: newUrl },
                      //   '',
                      //   newUrl
                      // );
                    }}
                    className="invstyle"
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedItem === item.id ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                    {item.name}
                  </CommandItem>
                </Link>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
