import React from 'react';

interface GalleryHeadingProps {
  title: string;
  searchTerm: string;
  setSearchTerm: (text: string) => void;
}

const GalleryHeading: React.FC<GalleryHeadingProps> = ({
  title,
  searchTerm,
  setSearchTerm
}) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="flex flex-col md:flex-row justify-between items-center pb-[50px] gap-6">
      {title && (
        <h1 className="text-[34px] lg:text-[44px] font-medium leading-[139%] text-brand-Black1 font-Mono text-center md:text-left">
          {title}
        </h1>
      )}
      <input
        type="text"
        value={searchTerm}
        className="px-5 py-[7px] bg-brand-GrayMain rounded-[67px] text-brand-DarkGray3 max-w-[433px] w-full font-light font-Mono"
        onChange={handleSearch}
        placeholder="Search"
      />
    </div>
  );
};

export default GalleryHeading;
