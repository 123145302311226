export interface BuildResponse {
  version_id: string;
  function_name: string;
  description: string;
  output_schema: Record<string, any>;
}

export interface GenerateSyntheticInputsRequest {
  name: string;
  n: number;
}

export interface GenerateSyntheticInputsResponse {
  example_inputs: string[];
}

export interface QueryResponse {
  response: Record<string, any>;
  description: string;
  latency_ms: number;
  num_input_tokens: number;
  num_output_tokens: number;
  input_cost_usd: number;
  output_cost_usd: number;
  fixed_cost_usd: number;
  warnings: string[];
}

export const generateSyntheticInputs = async (
  functionName: string,
  n: number = 2
): Promise<GenerateSyntheticInputsResponse> => {
  const response = await fetch('/api/demo/generate', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name: functionName, n })
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to generate synthetic inputs');
  }

  return response.json();
};

export const buildFeature = async (
  request: string,
  multimodal: boolean
): Promise<BuildResponse> => {
  const response = await fetch('/api/demo/build', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ request, multimodal })
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to build feature');
  }

  return response.json();
};

export const queryFeature = async (
  functionName: string,
  text: string
): Promise<QueryResponse> => {
  const response = await fetch('/api/demo/query', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ functionName, text })
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to query feature');
  }

  return response.json();
};

