import { demoListingSectionFields, faqSectionFields, galleryListingSectionFields, pricingListingSectionFields } from "../helper/sectionFields";

{/* Page Builder */ }
export const pageBuilder = /* groq */ `
pageBuilder[]->{
_type,
content[]{
(_type == "pricingListingSection") => {
${pricingListingSectionFields}
}, 
(_type == "demoListingSection") => {
${demoListingSectionFields}
}, 
(_type == "faqSection") => {
${faqSectionFields}
}, 
(_type == "galleryListingSection") => {
${galleryListingSectionFields}
}, 
}
}
`;
