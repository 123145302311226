export const projectId = "4xvxz9zp"

export const dataset = "production"

export const useCdn = false;

export const apiVersion = "2022-03-07";

export const readToken = process.env.SANITY_API_TOKEN || "";

export const writeToken = process.env.SANITY_WRITE_TOKEN || "";