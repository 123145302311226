'use client';

import React from 'react';
import { SignOut, StopImpersonation } from '@/utils/auth-helpers/server';
import { getRedirectMethod } from '@/utils/auth-helpers/settings';
import { useRouter } from 'next/navigation';
import { handleRequest } from '@/utils/auth-helpers/client';
import { cn } from '@/utils/cn';

export default function StopImpersonationForm({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    await handleRequest(formData, StopImpersonation, router);

    window.localStorage.setItem('impersonatedUserId', '');
    window.dispatchEvent(new Event('storage'));
    if (router) {
      router.refresh();
    }
  };
  const router = getRedirectMethod() === 'client' ? useRouter() : null;

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <button type="submit" className={cn('flex', className)}>
        {children || 'Stop impersonation'}
      </button>
    </form>
  );
}
