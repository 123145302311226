import { useSanityImage } from '@/app/(landing)/lib/sanity';
import { CustomImageType } from '@/app/(landing)/lib/types/common';
import NextImage from 'next/image';

const SanityImage: React.FC<{
  src: CustomImageType;
}> = ({ src, ...props }) => {
  const image = useSanityImage(src);
  return image ? (
    <NextImage {...image} alt={src?.alt || 'Image'} quality={100} {...props} />
  ) : null;
};
export default SanityImage;
