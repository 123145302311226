import React from 'react';

const RightIcon = ({ className }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      className={className}
    >
      <path d="M1 1L5 5L1 9" stroke="#FF5B45" strokeWidth="1.5" />
    </svg>
  );
};

export default RightIcon;
