import React from 'react'

const PlayIcon = ({className}:any) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" className={className}>
                <circle cx="30" cy="30" r="29.5" fill="white" stroke="#FF5B45" />
                <path d="M36 30L26 38L26 22L36 30Z" fill="#FF5B45" />
            </svg>
        </div>
    )
}

export default PlayIcon