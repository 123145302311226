'use client';
import {
  SquareFunction,
  GitCommitVertical,
  TriangleAlert,
  Plus,
  Image,
  ListFilter,
  ArrowDownWideNarrow,
  ArrowUpWideNarrow,
  PanelTopOpen,
  PanelTopClose,
  Icon
} from 'lucide-react';
import { textSquare } from '@lucide/lab';
import { useLocalStorage } from 'usehooks-ts';

import { Check } from 'lucide-react';
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command';

import { Button } from '@/components/ui/button';

import ComboBox from '@/components/ui/combobox';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui/tooltip';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@/utils/cn';
import { fromUUID } from '@/utils/helpers';
import {
  listFunctionsForCommandList,
  listVersions
} from '../function/[[...id_and_version]]/actions';
import {
  KeyboardKey,
  Tag
} from '../function/[[...id_and_version]]/components/misc';
import { ChevronsUpDown } from 'lucide-react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { Skeleton } from '@/components/ui/skeleton';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { useRouter } from 'next/navigation';
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const SchemaKeys = ({ keys }: { keys: string[] }) => {
  return (
    <div className="truncate w-fit">
      {keys.map((key) => (
        <Tag className="ml-1 bg-card/20" key={key}>
          {key}
        </Tag>
      ))}
    </div>
  );
};

const FunctionListRow = ({
  item,
  setKey,
  setOpen,
  inHeaderVariant,
  expanded,
  selectedKey
}: {
  item: Awaited<ReturnType<typeof listFunctionsForCommandList>>[number];
  setKey: (key: string) => void;
  setOpen: (open: boolean) => void;
  inHeaderVariant: boolean;
  expanded: boolean;
  selectedKey?: string;
}) => {
  const router = useRouter();
  return (
    <CommandItem
      key={item.key}
      onSelect={(v) => {
        console.log('event onSelect');
        setKey(v);
        setOpen(false);
        router.push(item.route);
      }}
      className="invstyle group cursor-pointer"
    >
      <a
        href={item.route}
        key={item.key}
        onClick={(event) => {
          event.stopPropagation();
        }}
        role="tab"
        className="flex flex-row justify-between w-full"
      >
        <div
          className={cn(
            'flex flex-row items-center gap-3',
            !(inHeaderVariant && !expanded) && 'w-[80%]'
          )}
        >
          {/* Check indicator */}
          {inHeaderVariant && (
            <span className="w-5 truncate">
              <Check
                className={cn(
                  'size-4',
                  selectedKey === item.key ? 'opacity-100' : 'opacity-0'
                )}
              />
            </span>
          )}

          {/* Multimodal indicator */}
          <span className="w-4">
            {item.multimodal ? (
              <Image className="size-4" />
            ) : (
              <Icon iconNode={textSquare} className="size-4" />
            )}
          </span>
          {/* Function name */}
          <span className="w-48 truncate">{item.name}</span>

          {expanded && (
            <span className="w-32 truncate opacity-75">
              {timeAgo.format(item.createdAt)}
            </span>
          )}

          {/* Description */}
          <span
            className={cn(
              'truncate opacity-75',
              inHeaderVariant ? 'w-96' : 'w-80 '
            )}
          >
            {item.description}
          </span>

          {/* Num of versions */}
          {expanded && (
            <>
              {/* <span className="w-32 truncate opacity-75">
            {timeAgo.format(item.createdAt)}
          </span> */}
              {/* <span className="w-28 truncate opacity-75">
            {item.versionNumber + 1}
          </span> */}
              <span className="w-32 truncate opacity-75">
                {item.totalRecentCalls + 1}
              </span>
              {/* <span className="w-16 truncate opacity-75">
            {item.totalRecentTokens + 1}
          </span>
          <span className="w-16 truncate opacity-75">
            {item.totalRecentCost + 1}
          </span> */}
            </>
          )}
        </div>

        {/* Output keys */}
        {(!inHeaderVariant || expanded) && (
          <SchemaKeys keys={item.outputKeys} />
        )}
      </a>
    </CommandItem>
  );
};

export function ComboBoxFunctionBuilder({
  items,
  selectedKey,
  icon = <></>,
  skeletonClassName = '',
  functionSelectorContent
}: {
  items?: Awaited<ReturnType<typeof listFunctionsForCommandList>>;
  selectedKey?: string;
  icon?: React.ReactNode;
  skeletonClassName?: string;
  functionSelectorContent?: React.ReactNode;
}) {
  const key = selectedKey;

  let currentItem;
  if (selectedKey === 'new') {
    currentItem = 'New function';
  } else if (items) {
    currentItem = items.find((item) => item.key === key)?.name;
  } else {
    currentItem = <Skeleton className={cn('w-20 h-5', skeletonClassName)} />;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          role="combobox"
          size="sm"
          className="justify-between" // was min-w-[100px]
        >
          {icon}
          {currentItem}
          <KeyboardKey
            showPlatformCmdKey
            commandKeyClassName="size-3"
            className="ml-2 gap-1"
          >
            K
          </KeyboardKey>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="max-w-[90vw] md:max-w-[80vw] lg:max-w-[70vw] xl:max-w-[60vw] 2xl:max-w-[50vw] w-fit p-0 invstyle drop-shadow-2xl shadow-lg shadow-accent/5 bg-transparent border-0"
        align="start"
        sideOffset={18}
      >
        {functionSelectorContent}
      </PopoverContent>
    </Popover>
  );
}

export const FunctionSelector = ({
  currentFunctionId,
  inHeaderVariant = false,
  showNumRecents = 3
}: {
  currentFunctionId: string;
  inHeaderVariant?: boolean;
  showNumRecents?: number;
}) => {
  const [items, setItems] =
    useState<Awaited<ReturnType<typeof listFunctionsForCommandList>>>();

  useEffect(() => {
    const fetch = async () => {
      setItems(await listFunctionsForCommandList());
    };
    fetch();
  }, [currentFunctionId]);

  const [open, setOpen] = React.useState(false);
  const [key, setKey] = React.useState(currentFunctionId);

  const [expanded, setExpanded_, removeExpanded] = useLocalStorage<boolean>(
    `functionListExpanded${inHeaderVariant ? '_header' : ''}`,
    false
  );
  const setExpanded = (expanded: boolean) => {
    setExpanded_(expanded);
    if (!expanded) {
      setSortKey('createdAt');
      setSortAsc(true);
      setMultimodalFilter(null);
    }
  };
  const [sortKey, setSortKey] = useState<string | null>('createdAt');
  const [sortAsc, setSortAsc] = useState(true);
  const [multimodalFilter, setMultimodalFilter] = useState<boolean | null>(
    null
  );

  let itemsSorted = useMemo(() => {
    if (items === undefined) return items;

    const itemsSorted = items
      .slice()
      .filter(
        (item) =>
          multimodalFilter === null || item.multimodal === multimodalFilter
      );

    return itemsSorted.sort((a, b) => {
      if (!sortAsc) {
        [a, b] = [b, a];
      }

      if (sortKey === 'name') {
        return a.name.localeCompare(b.name);
      } else if (sortKey === 'description') {
        return a.description.localeCompare(b.description);
      } else if (sortKey === 'createdAt') {
        return b.createdAt.getTime() - a.createdAt.getTime();
      } else if (sortKey === 'versionNumber') {
        return b.versionNumber - a.versionNumber;
      } else if (sortKey === 'totalRecentCalls') {
        return b.totalRecentCalls - a.totalRecentCalls;
      } else if (sortKey === 'outputKeys') {
        return b.outputKeys.length - a.outputKeys.length;
      }
      return 0;
    });
  }, [items, sortKey, sortAsc, multimodalFilter]);

  const [fnIdToLastOpened, setFnIdToLastOpened, removeFnIdToLastOpened] =
    useLocalStorage<Record<string, number>>('fnIdToLastOpened', {});
  const recentItems = useMemo(() => {
    const recentFnIds = Object.keys(fnIdToLastOpened)
      .sort((a, b) => fnIdToLastOpened[b] - fnIdToLastOpened[a])
      .slice(0, showNumRecents);
    return recentFnIds
      .map((fnId) => itemsSorted?.find((item) => item.key === fnId) || null)
      .filter((item) => item !== null);
  }, [fnIdToLastOpened, itemsSorted]);

  itemsSorted = useMemo(
    () => itemsSorted?.filter((item) => !recentItems.includes(item)),
    [itemsSorted, recentItems]
  );

  const SortColumn = ({
    propKey,
    title,
    className
  }: {
    propKey: string;
    title: React.ReactNode;
    className?: string;
  }) => {
    return (
      <button
        className={cn('flex flex-row items-center gap-1', className)}
        onClick={() => {
          console.log('propKey', propKey, 'sortKey', sortKey);
          if (sortKey === propKey) {
            setSortAsc(!sortAsc);
          } else {
            setSortKey(propKey);
            setSortAsc(true);
          }
        }}
      >
        {title}
        {sortKey === propKey &&
          (sortAsc ? (
            <ArrowDownWideNarrow className="size-4 opacity-75" />
          ) : (
            <ArrowUpWideNarrow className="size-4 opacity-75" />
          ))}
      </button>
    );
  };

  const listRef = useRef<HTMLDivElement>(null);

  const commandContent = (
    <>
      <div className="flex flex-row items-center gap-2 relative">
        <CommandInput
          placeholder="Type to search.."
          className={cn(
            'text-foreground placeholder:text-foreground/70 w-full'
          )}
          classNameContainer={cn(
            'transition-all bg-primary/30',
            expanded && 'border-none'
          )}
          autoFocus={true}
          onValueChange={(value) => {
            // scroll to top of list when search is updated
            if (listRef.current) listRef.current.scrollTop = 0;
          }}
        />
        <Button
          variant="link"
          size="iconSm"
          className="absolute right-2 top-1/2 -translate-y-1/2 text-card"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <PanelTopClose className="size-4 inline-block" />
          ) : (
            <PanelTopOpen className="size-4 inline-block" />
          )}
        </Button>
      </div>

      <div
        className={cn(
          'relative flex select-none items-center rounded-sm text-sm font-medium bg-primary/30 transition-all px-3 gap-2',
          expanded ? 'border-b border-muted/30 py-2.5' : 'max-h-0'
        )}
      >
        {expanded && (
          <>
            <div className="flex flex-row items-center w-[80%] gap-3">
              {/* Spacer for check indicator */}
              {inHeaderVariant && <span className="size-5 truncate"></span>}

              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    className="flex flex-row items-center gap-0.5 w-4"
                    onClick={() => {
                      if (multimodalFilter === null) {
                        setMultimodalFilter(true);
                      } else if (multimodalFilter === true) {
                        setMultimodalFilter(false);
                      } else {
                        setMultimodalFilter(null);
                      }
                    }}
                  >
                    {multimodalFilter === null && (
                      <ListFilter className="size-4" />
                    )}
                    {multimodalFilter && <Image className="size-4" />}
                    {multimodalFilter === false && (
                      <Icon iconNode={textSquare} className="size-4" />
                    )}
                  </button>
                </TooltipTrigger>
                <TooltipContent side="left" sideOffset={16}>
                  Filter by function modality
                </TooltipContent>
              </Tooltip>

              <SortColumn
                propKey="name"
                title="Function name"
                className="w-48 truncate"
              />
              {expanded && (
                <SortColumn
                  propKey="createdAt"
                  title="Created"
                  className="w-32"
                />
              )}

              <SortColumn
                propKey="description"
                title="Description"
                className={cn(inHeaderVariant ? 'w-96' : 'w-80 ')}
              />
              {expanded && (
                <>
                  {/* <SortColumn
                    propKey="createdAt"
                    title="Created"
                    className="w-32"
                  /> */}
                  {/* <SortColumn
                    propKey="versionNumber"
                    title="Versions"
                    className="w-28 truncate"
                  /> */}
                  <SortColumn
                    propKey="totalRecentCalls"
                    title="Recent calls"
                    className="w-32 truncate"
                  />
                </>
              )}
            </div>
            {(!inHeaderVariant || expanded) && (
              <SortColumn
                propKey="outputKeys"
                title="Output keys"
                className="ml-auto pl-4"
              />
            )}
          </>
        )}
      </div>
      <CommandList className="invstyle" ref={listRef}>
        <CommandEmpty>No functions found...</CommandEmpty>
        {/* <CommandGroup className="invstyle">
          <CommandItem
            key="create-new"
            onSelect={(v) => {
              console.log('event onSelect');
              setKey(v);
              setOpen(false);
              // router.push(item.route);
            }}
            className="invstyle group cursor-pointer"
          >
            <a
              href={'/function/new'}
              onClick={(event) => {
                event.stopPropagation();
              }}
              role="tab"
              className="flex flex-row justify-between w-full"
            >
              <div
                className={cn(
                  'flex flex-row items-center gap-3',
                  !(inHeaderVariant && !expanded) && 'w-[80%]'
                )}
              >
                {inHeaderVariant && <span className="w-5 truncate"></span>}
                <span className="w-4">
                  <PlusIcon className="size-4" />
                </span>
                <span className="w-48 truncate">Create new function</span>
              </div>
            </a>
          </CommandItem>
        </CommandGroup> */}

        {items && (
          <>
            <CommandGroup className="invstyle" heading="Recently viewed">
              {recentItems.map((item) => (
                <FunctionListRow
                  item={item}
                  setKey={setKey}
                  setOpen={setOpen}
                  inHeaderVariant={inHeaderVariant}
                  expanded={expanded}
                  selectedKey={currentFunctionId}
                  key={item.key}
                />
              ))}
            </CommandGroup>
            <CommandGroup className="invstyle" heading="All functions">
              {itemsSorted?.map((item) => (
                <FunctionListRow
                  item={item}
                  setKey={setKey}
                  setOpen={setOpen}
                  inHeaderVariant={inHeaderVariant}
                  expanded={expanded}
                  selectedKey={currentFunctionId}
                  key={item.key}
                />
              ))}
            </CommandGroup>
          </>
        )}
      </CommandList>
    </>
  );

  // if (asCommandDialog) {
  //   return (
  //     <CommandDialog
  //       open={open}
  //       onOpenChange={setOpen}
  //       className={cn('invstyle bg-popover/90 transition-all min-w-full')}
  //       dialogContentClassName="min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] xl:min-w-[60vw] 2xl:min-w-[50vw]"
  //     >
  //       {commandContent}
  //     </CommandDialog>
  //   );
  // }

  if (inHeaderVariant) {
    React.useEffect(() => {
      const down = (e: KeyboardEvent) => {
        console.log(e);

        if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          setOpen((open) => !open);
        }
      };
      document.addEventListener('keydown', down);
      return () => document.removeEventListener('keydown', down);
    }, []);

    return (
      <>
        <ComboBoxFunctionBuilder
          items={items}
          selectedKey={currentFunctionId}
          icon={<SquareFunction className="size-5 mr-2" />}
          skeletonClassName="w-36"
          functionSelectorContent={
            <Command
              className={cn(
                'invstyle bg-popover/90 transition-all',
                expanded && inHeaderVariant
                  ? 'w-[90vw] md:w-[80vw] lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw]'
                  : ''
              )}
            >
              {commandContent}
            </Command>
          }
        />
        <CommandDialog
          open={open}
          onOpenChange={setOpen}
          className={cn('invstyle bg-popover/90 transition-all min-w-full')}
          dialogContentClassName="min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] xl:min-w-[60vw] 2xl:min-w-[50vw]"
        >
          {commandContent}
        </CommandDialog>
      </>
    );
  } else {
    return (
      <Command
        className={cn(
          'invstyle bg-popover/90 transition-all',
          expanded && inHeaderVariant
            ? 'w-[90vw] md:w-[80vw] lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw]'
            : '',
          'bg-popover/90 max-h-[21.25rem] max-w-[90%] md:max-w-[80%] lg:max-w-[70%] xl:max-w-[60%] 2xl:max-w-[50%]'
        )}
      >
        {commandContent}
      </Command>
    );
  }

  // return (
  //   <>
  //     <Command
  //       className={cn(
  //         'invstyle bg-popover/90 transition-all',
  //         expanded && inHeaderVariant
  //           ? 'w-[90vw] md:w-[80vw] lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw]'
  //           : '',
  //         className
  //       )}
  //     >
  //       {commandContent}
  //     </Command>
  // <CommandDialog
  //   open={open}
  //   onOpenChange={setOpen}
  //   className={cn('invstyle bg-popover/90 transition-all min-w-full')}
  //   dialogContentClassName="min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] xl:min-w-[60vw] 2xl:min-w-[50vw]"
  // >
  //   {commandContent}
  // </CommandDialog>
  //   </>
  // );
};

export const FunctionVersionSelector = ({
  functionId,
  version,
  versionAlias
}: {
  functionId: string;
  version?: number;
  versionAlias?: string;
}) => {
  const [allVersions, setAllVersions] = useState<
    Awaited<ReturnType<typeof listVersions>>
  >([]);

  useEffect(() => {
    const fetch = async () => {
      if (functionId !== 'new') {
        setAllVersions(await listVersions(functionId));
      } else {
        setAllVersions([]);
      }
    };
    fetch();
  }, [functionId, version, versionAlias]);

  const selVersionNumber =
    version === undefined || version === null || version < 0 ? '0' : version;

  const suffixIcon =
    version !== undefined &&
    version !== null &&
    allVersions[0] &&
    allVersions.length > 0 &&
    selVersionNumber !== allVersions[0]?.version_number ? (
      <Tooltip>
        <TooltipTrigger asChild>
          <TriangleAlert className="ml-2 size-5 pb-0.5 my-auto text-orange-500" />
        </TooltipTrigger>
        <TooltipContent sideOffset={8} side="right">
          This is an older version of the function. You may want to switch to
          the latest version.
        </TooltipContent>
      </Tooltip>
    ) : null;

  return (
    <h1 className="flex gap-1">
      <FunctionSelector currentFunctionId={functionId} inHeaderVariant />
      {functionId !== 'new' && (
        <ComboBox
          items={allVersions.map((version) => ({
            id: `v${version.version_number}`,
            name: (
              <>
                v{version.version_number}
                {version.alias && (
                  <span className="opacity-60 ml-2">{version.alias}</span>
                )}
              </>
            ),
            route:
              version === allVersions[0]
                ? `/function/${fromUUID(functionId)}`
                : `/function/${fromUUID(functionId)}/v${version.version_number}`
          }))}
          selectedItem={`v${selVersionNumber}`}
          icon={<GitCommitVertical className="size-5 mr-2" />}
          suffixIcon={suffixIcon}
        />
      )}
    </h1>
  );
};

export const CreateNewFunctionButton = () => {
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      console.log(e);

      if (e.key === 'u' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        window.location.href = '/function/new';
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  // const router = useRouter();
  return (
    <Button
      variant="secondary"
      size="sm"
      className="gap-2"
      onClick={() => {
        // if (window.location.pathname === '/function/new') {
        //   window.location.href = '/function/new';
        // } else {
        //   router.push('/function/new');
        // }
        window.location.href = '/function/new';
      }}
    >
      <Plus className="size-4" />
      Create new function
      <KeyboardKey
        className="gap-1"
        commandKeyClassName="size-3"
        showPlatformCmdKey
        unstyled
      >
        U
      </KeyboardKey>
    </Button>
  );
};
