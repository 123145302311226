
import {
    PortableTextBlock,
    PortableTextComponentProps,
    toPlainText,
  } from "@portabletext/react";
  import React from "react";
  import slugify from "slugify";
  import BlockQuote from "../blockQuote";
  
  export type PortableTextStyleType =
    | "normal"
    | "blockquote"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
  
  const PricingRichTextBlock = ({
    value,
    children,
  }: PortableTextComponentProps<PortableTextBlock>) => {
    const id = slugify(toPlainText(value), { lower: true });
    const style = value.style as PortableTextStyleType;
    switch (style) {
      case "h1":
        return <h1 id={id} className="text-[40px] font-semibold  leading-[130%]">{children}</h1>;
      case "h2":
        return <h2 id={id} className="text-[28px] font-semibold">{children}</h2>;
      case "h3":
        return <h3 id={id} className="text-2xl font-semibold">{children}</h3>;
      case "h4":
        return <h4 id={id} className="[&>strong]:text-xl text-xl font-semibold ">{children}</h4>;
      case "h5":
        return <h5 id={id}>{children}</h5>;
      case "h6":
        return <h6 id={id}>{children}</h6>;
      case "blockquote":
        return <BlockQuote>{children}</BlockQuote>;
      default:
        return <p className="">{children}</p>;
    }
  };
  
  export default PricingRichTextBlock;
  