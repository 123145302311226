'use client';
import React, { useState } from 'react';
import { GalleryListingSectionType } from '../../lib/types/sectionsType';
import GalleryCard from './GalleryCard';
import GalleryHeading from './GalleryHeading';
import CategoryFilter from './CategoryFilter';
import RightIcon from '../icons/RightIcon';

export interface FilterType {
  title: string;
  value: string;
}

const filters: FilterType[] = [
  { title: 'Recommended', value: 'recomended' },
  { title: 'Popular', value: 'popular' },
  { title: 'Latest', value: 'latest' }
];

const GalleryListingSection: React.FC<GalleryListingSectionType> = (block) => {
  const { allCategories, allGalleryPosts, title } = block || {};
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState('recomended');

  const toggleCategory = (categoryName: string) => {
    setSelectedCategories((prevSelectedCategories) => {
      if (prevSelectedCategories.includes(categoryName)) {
        return prevSelectedCategories.filter(
          (category) => category !== categoryName
        );
      } else {
        return [...prevSelectedCategories, categoryName];
      }
    });
  };

  const filteredData = allGalleryPosts?.filter((item) => {
    const matchesSearchTerm = item?.title
      ?.toLowerCase()
      ?.includes(searchTerm?.trim()?.toLowerCase());

    const matchesCategory = selectedCategories?.length
      ? item?.categories?.some((category) =>
          selectedCategories?.includes(category?.name)
        )
      : true;

    const matchesFilter = selectedFilter === 'popular' ? item?.isPopular : true;

    return matchesSearchTerm && matchesCategory && matchesFilter;
  });

  const sortedData =
    selectedFilter === 'latest'
      ? filteredData?.sort((a, b) => {
          return (
            new Date(b?.publishDate)?.getTime() -
            new Date(a?.publishDate)?.getTime()
          );
        })
      : filteredData;

  return (
    <div className="pt-[120px] md:pt-[162px]">
      <div className="container">
        <GalleryHeading
          title={title}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <div className="md:border-t-[1px] md:border-b-[1px] border-brand-GrayMain flex flex-col md:flex-row justify-between ">
          <CategoryFilter
            allCategories={allCategories}
            selectedCategories={selectedCategories}
            toggleCategory={toggleCategory}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filters={filters}
          />
          <div className="max-w-full md:max-w-[1100px] w-full md:pt-10 md:pl-10 xl:pl-[61px] pb-14">
            <div className="flex justify-between items-center">
              <h2 className="text-[20px] md:text-[26px] xlg:text-[32px] text-brand-Black1 font-medium leading-[139%] font-Mono">
                {
                  filters?.find((filter) => filter?.value === selectedFilter)
                    ?.title
                }
              </h2>
              <div className="flex items-center gap-1.5 cursor-pointer">
                <span className="text-sm font-medium text-brand-Orange font-Mono">{`See more`}</span>
                <RightIcon className="" />
              </div>
            </div>
            {
              <div className="mt-[30px] grid grid-cols-1 xlg:grid-cols-2 gap-5">
                {sortedData &&
                  sortedData?.length > 0 &&
                  sortedData.map((item, index) => (
                    <GalleryCard {...item} key={index} />
                  ))}
              </div>
            }

            {sortedData && sortedData?.length <= 0 && (
              <p className="text-center text-lg em:text-xl font-Mono font-medium w-full mt-[30px]">
                No gallery posts found.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryListingSection;
