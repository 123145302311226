'use client';
import React, { useState, useEffect } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark.css';
import { CodeBlockSectionType } from '@/app/(landing)/lib/types/common';
import { Check, Copy } from 'lucide-react';

const CodeBlockBox: React.FC<{ value: CodeBlockSectionType }> = ({ value }) => {
  const { description, language = 'python' } = value;
  const [isCopied, setIsCopied] = useState(false);
  const [highlightedCode, setHighlightedCode] = useState('');
  useEffect(() => {
    if (description) {
      const highlighted = hljs.highlight(language, description).value;
      setHighlightedCode(highlighted);
    }
  }, [description]);

  const handleCopy = () => {
    navigator.clipboard.writeText(description).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className="relative my-5">
      <div className="absolute top-0 right-0 px-4 py-2 flex items-center justify-between gap-2 w-full">
        <div className="flex gap-1 items-center">
          <span className="w-3 h-3 bg-red-500 rounded-full"></span>
          <span className="w-3 h-3 bg-yellow-400 rounded-full"></span>
          <span className="w-3 h-3 bg-green-500 rounded-full"></span>
        </div>
        <div className="flex items-center justify-end gap-3">
          {language && (
            <div className="text-white border rounded px-[8px] py-[2px] text-sm">
              {language}
            </div>
          )}

          <button
            onClick={handleCopy}
            className="text-gray-400 hover:text-gray-200 flex items-center"
          >
            {isCopied ? (
              <Check className="w-5 h-5" />
            ) : (
              <Copy className="w-5 h-5" />
            )}
          </button>
        </div>
      </div>

      <pre className="text-white rounded-lg  bg-gray-800 pt-[40px] px-4 pb-4 overflow-x-auto w-full">
        <code
          className={`language-${language} text-white`}
          dangerouslySetInnerHTML={{ __html: highlightedCode }}
        />
      </pre>
    </div>
  );
};

export default CodeBlockBox;
