'use client';

import React from 'react';
import { createClient } from '@/utils/supabase/client';
import { useRouter } from 'next/navigation';

export default function SignoutButton({
  children
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const supabase = createClient();
  const router = useRouter();

  return (
    <button
      type="button"
      className="flex gap-x-2 items-center w-full h-full"
      onClick={async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
          console.error('Error signing out:', error.message);
        } else {
          console.log('Successfully signed out');
          router.push('/');
        }
      }}
    >
      {children || 'Log out'}
    </button>
  );
}
