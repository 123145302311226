'use client';
import React, { useEffect, useState, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import clsx from 'clsx';
import { BuildSectionType } from '@/app/(landing)/lib/types/sectionsType';
import Image from '../../Image';
import buildlottie from '@/public/lottie/Builder.json';

const DeskTop: React.FC<{ block: BuildSectionType }> = ({ block }) => {
  const { title } = block || {};
  const [showGif, setShowGif] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const lottieRef = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<AnimationItem | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!animationRef.current && lottieRef.current) {
              animationRef.current = lottie.loadAnimation({
                container: lottieRef.current,
                animationData: buildlottie,
                renderer: 'svg',
                loop: false,
                autoplay: true,
              });
              animationRef.current.addEventListener('enterFrame', () => {
                setShowGif(true);
              });
            } else {
              animationRef.current?.play();
            }
          } else {
            animationRef.current?.pause();
          }
        });
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      observer.disconnect();
      animationRef.current?.destroy();
    };
  }, []);

  return (
    <div ref={sectionRef} className="mb-[100px] lg:mb-[90px] hidden lg:block">
      <div className="container">
        {title && (
          <h2
            className="[&>p]:text-[32px] sm:[&>p]:text-[40px] lg:[&>p]:text-[54px] text-center [&>p>strong]:font-thin [&>p>strong]:px-[2px] font-medium [&>p>span]:px-2 lg:[&>p>span]:px-6 [&>p>span]:block esm:[&>p>span]:inline-block [&>p>span]:text-[38px] sm:[&>p>span]:text-[55px] lg:[&>p>span]:text-[96px] [&>p>span]:text-brand-Orange [&>p>span]:font-extralight pb-6"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <span className="text-xs font-light tracking-[2.88px] uppercase text-brand-Silver block lg:hidden pt-6 px-4">
          {`Productivity`}
        </span>
      </div>
      <div className="bg-buildSectionBg bg-cover bg-no-repeat bg-center">
        <div className="container">
          <div className="pt-[30px] pb-16 lg:pb-0 overflow-hidden relative">
            {/* Lottie Animation */}
            <div ref={lottieRef} className="w-full h-full" />
            {/* GIF and Star Icon */}
            <div>
              <Image
                src="/images/buildWithGif.gif"
                width={590}
                height={500}
                alt="ai-gif"
                className={clsx(
                  'absolute bottom-[-110px] xlg:bottom-[-181px] right-[-98px] xlg:right-[-130px] mix-blend-exclusion max-w-[280px] sm:max-w-[350px] md:max-w-[400px] xlg:max-w-[586px] w-full h-auto z-0 transition duration-300 ease-linear',
                  showGif ? 'scale-100' : 'scale-0'
                )}
              />
              <div
                className={clsx(
                  'flex flex-col gap-[9px] absolute right-[0px] sm:right-[34px] xlg:right-[90px] bottom-[10px] sm:bottom-[40px] md:bottom-[60px] xlg:bottom-[85px] transition duration-300 ease-linear',
                  showGif ? 'scale-100' : 'scale-0'
                )}
              >
                <Image
                  src="/images/orangeStarIcon.png"
                  alt="starIcon"
                  width={19}
                  height={23}
                />
                <p className="text-[10px] md:text-xs font-light max-w-[60px] md:max-w-[84px] w-full text-left text-brand-ShadowGray">
                  {`Work with an `}
                  <span className="font-semibold">{`AI Agent`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="text-xs font-light tracking-[2.88px] uppercase text-brand-Silver text-center hidden lg:block pt-6">
        {`Productivity`}
      </span>
    </div>
  );
};

export default DeskTop;
