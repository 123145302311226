import { categoryFields, demoFields, galleryFields, planFields, questionAndAnswerFields } from "../globalFields";

{/* Pricing Listing section */ }
export const pricingListingSectionFields =/* groq */`
_type,
title,
description,
plans[]->{
${planFields}
}
`
{/* Demo Listing Section  */ }
export const demoListingSectionFields =/* groq */`
_type,
title,
description,
demoLists[]{
${demoFields}
}
`

{/* Faq Section  */ }

export const faqSectionFields =/* groq */`
_type,
title,
questionAndAnswers[]{
${questionAndAnswerFields}
}
`

{/* Gallery Listing Section  */ }

export const galleryListingSectionFields =/* groq */`
_type,
title,
"allGalleryPosts":*[_type=="gallery"]{
${galleryFields}
},
"allCategories":*[_type=="category"]{
${categoryFields}
}
`