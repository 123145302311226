import { Card } from '@/components/ui/card';
import { cn } from '@/utils/cn';
import { ChevronDown, ChevronUp, Command, FunctionSquare } from 'lucide-react';
import { CSSTransition } from 'react-transition-group';

import UseAnimations from 'react-useanimations';
import loading2 from 'react-useanimations/lib/loading2';
import SyntaxHighlighter, {
  SyntaxHighlighterProps
} from 'react-syntax-highlighter';
import { useState } from 'react';

// loading indicator for function querying
export const LoadingIndicator: React.FC<{
  size?: number;
}> = ({ size }) => (
  <UseAnimations animation={loading2} size={size} className="opacity-90" />
);

// text that is truncated after some line/word/char limit
// and can be expanded or truncated by clicking on it
export const TruncatedText = ({
  text,
  numLines,
  numWords,
  numChars,
  className
}: {
  text: string;
  numLines?: number;
  numWords?: number;
  numChars?: number;
  className?: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Truncate the text to the specified number of lines, words, or characters
  let truncatedText = text;
  if (numLines) {
    const arr = text.split('\n');
    const truncation =
      arr.length > numLines ? arr.slice(0, numLines).join('\n') : text;
    truncatedText =
      truncation.length < truncatedText.length ? truncation : truncatedText;
  }
  if (numWords) {
    const arr = text.split(' ');
    const truncation =
      arr.length > numWords ? arr.slice(0, numWords).join(' ') : text;
    truncatedText =
      truncation.length < truncatedText.length ? truncation : truncatedText;
  }
  if (numChars) {
    const truncation = text.slice(0, numChars);
    truncatedText =
      truncation.length < truncatedText.length ? truncation : truncatedText;
  }

  const isTruncated = text.length > truncatedText.length;
  const displayText = isTruncated ? (
    <div className="items-center">
      {truncatedText}
      <span className="font-[500] tracking-widest ml-2 bg-secondary/75 px-1 rounded-sm">
        ...
      </span>
    </div>
  ) : (
    text
  );

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      onClick={toggleExpand}
      className={cn(
        'w-full h-full',
        isTruncated && 'cursor-pointer hover:bg-muted/40',
        className
      )}
    >
      {isExpanded ? text : displayText}
    </div>
  );
};

// json highlighting for function output (custom styles defined in globals.css)
export const HighlightedJson: React.FC<SyntaxHighlighterProps> = ({
  children,
  useInlineStyles = false,
  wrapLongLines = true,
  ...props
}) => (
  <SyntaxHighlighter
    language="json"
    useInlineStyles={useInlineStyles}
    wrapLongLines={wrapLongLines}
    codeTagProps={{ className: 'hljs-other' }}
    {...props}
  >
    {children}
  </SyntaxHighlighter>
);

// down-arrow symbol between function building steps
export const Chevron = ({ className }: { className?: string }) => (
  <div
    className={cn(
      'flex justify-around items-center w-full my-6 2xl:my-10',
      className
    )}
  >
    <ChevronDown className="size-5 text-muted-foreground" />
  </div>
);

// card component for function building steps
export const FBCard = ({
  children,
  className,
  iconType: IconType,
  prependChevronSpacer = true,
  ...props
}: React.ComponentProps<typeof Card> & {
  iconType: React.ElementType;
  prependChevronSpacer?: boolean;
}) => (
  <>
    {prependChevronSpacer && <Chevron />}
    <div className="flex flex-row items-start w-[90%] sm:w-[85%] md:w-4/5 lg:w-2/3 xl:w-[45%] 2xl:w-[35%]">
      <div className="size-10 bg-primary mr-5 mt-7 rounded-full flex justify-center items-center shadow-[0_1px_3px_0_rgba(0,0,0,0.1),0_1px_2px_-1px_rgba(0,0,0,0.1),inset_0_0_4px_0_rgba(255,255,255,0.4)]">
        <IconType className="size-4 text-card" />
      </div>
      <Card
        className={cn(
          'flex-grow shadow-[0px_1px_2px_0px_rgba(0,0,1,0.05),0_0px_0px_5px_rgba(0,0,0,0.05)]',
          // 'flex-grow shadow-sm',
          className
        )}
        {...props}
      >
        {children}
      </Card>
    </div>
  </>
);

// handles transition animations for the steps of the function building process (like scrolling to the next step)
export const CardTransitionHandler = ({
  scrollTargetRef,
  stageTransitionRef,
  children,
  timeout = 0,
  visible = true,
  className
}: {
  scrollTargetRef: React.RefObject<HTMLDivElement>;
  stageTransitionRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  timeout?: number;
  visible?: boolean;
  className?: string;
}) => {
  return (
    <div className="w-full min-h-40" ref={scrollTargetRef}>
      {visible && (
        <CSSTransition
          in={true}
          appear={true}
          timeout={timeout}
          nodeRef={stageTransitionRef}
          classNames={{
            appear: 'scale-95 opacity-0 -translate-y-5',
            appearActive: 'scale-100 opacity-100 translate-y-0'
          }}
          unmountOnExit
        >
          <div
            className={cn(
              'w-full flex flex-col items-center transition-all scale-100 opacity-100 duration-300 translate-y-0',
              className
            )}
            ref={stageTransitionRef}
          >
            {children}
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

export const FunctionIcon = () => (
  <div className="size-10 bg-primary flex justify-center items-center shadow-[0_0px_4px_0px_hsl(var(--wecopink))] mr-6 relative top-1 ml-1">
    <FunctionSquare
      className="size-8 text-background rounded-md"
      strokeLinecap="square"
      viewBox="4 4 16 16"
    />
  </div>
);

export const LoadingOverlay = ({ text }: { text?: string }) => (
  <div className="absolute inset-0 flex flex-col justify-center items-center bg-input/25">
    {text && (
      <div className="bg-card rounded-md">
        <div className="text-muted-foreground p-5 text-sm bg-input/25">
          {text}
        </div>
      </div>
    )}
    <LoadingIndicator />
  </div>
);

// tag component for version number in AB testing, output keys in function list, llm tags,...
export const Tag = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <span
    className={cn(
      'text-xs bg-muted/80 px-1 rounded-sm group-aria-selected:bg-primary/50 group-aria-selected:text-accent',
      className
    )}
  >
    {children}
  </span>
);

// for indicating keyboard shortcuts in the UI
export const KeyboardKey = ({
  children,
  showPlatformCmdKey = false,
  unstyled = false,
  className,
  commandKeyClassName
}: {
  children: React.ReactNode;
  showPlatformCmdKey?: boolean;
  unstyled?: boolean;
  className?: string;
  commandKeyClassName?: string;
}) => {
  const isWindows =
    typeof window !== 'undefined' &&
    window.navigator.userAgent.indexOf('Win') !== -1;
  // The code was using a deprecated API (navigator.platform) to detect Windows, which was replaced with a more reliable method using window.navigator.userAgent to improve cross-environment compatibility and eliminate deprecation warnings.

  return (
    <kbd
      className={cn(
        'text-xs flex items-center gap-0.5 rounded-sm px-1 py-1 max-h-[22px] text-foreground/60',
        !unstyled && 'border border-muted-foreground/30 bg-accent ',
        className
      )}
    >
      {showPlatformCmdKey && (
        <>
          {isWindows ? (
            <ChevronUp className={cn('size-3', commandKeyClassName)} />
          ) : (
            <Command className={cn('size-2.5', commandKeyClassName)} />
          )}
        </>
      )}
      {children}
    </kbd>
  );
};
