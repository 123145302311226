'use client';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { QuestionAndAnswerType } from '@/app/(landing)/lib/types/sectionsType';
import { cn } from '@/utils/cn';
import React from 'react';
import { Minus, Plus } from 'lucide-react';

export interface QuestionAndAnswersProps {
  questionAndAnswers: QuestionAndAnswerType[];
}

const QuestionAndAnswers: React.FC<QuestionAndAnswersProps> = ({
  questionAndAnswers
}) => {
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const handleClick = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full mt-8 em:mt-[50px] md:mt-[75px]"
    >
      {questionAndAnswers &&
        questionAndAnswers.map((item, index) => {
          const { question, answer } = item || {};
          const isSelected = index === activeIndex;
          return (
            <AccordionItem
              value={`${index}`}
              className={cn(
                'w-full [&[data-state=open]]:bg-[#F8F8F9] border-b-0 '
              )}
              key={index}
            >
              <AccordionTrigger
                className="flex justify-between items-center py-6 pl-[10px] sm:pl-[30px] pr-[10px] sm:pr-10 cursor-pointer border-t-[1px] border-[#E3ECF8] focus-visible:outline-none"
                onClick={() => handleClick(index)}
              >
                {question && (
                  <h4
                    className={cn(
                      'text-sm em:text-lg font-DM font-medium leading-normal pr-2 max-w-[800px] text-left ',
                      isSelected ? 'text-brand-Orange' : 'text-[#292D33]'
                    )}
                  >
                    {question}
                  </h4>
                )}
                <div className="w-12 mr-0 ml-auto flex justify-end">
                  {isSelected ? (
                    <div className="w-10 h-10 text-center flex flex-col justify-center items-center border-[1px] border-brand-Orange rounded-full text-white bg-brand-Orange BasicTransition">
                      <Minus className="size-5 sm:size-6" />
                    </div>
                  ) : (
                    <div className="w-10 h-10 text-center flex flex-col justify-center items-center border-[1px] border-brand-Orange rounded-full text-brand-Orange BasicTransition">
                      <Plus className="size-5 sm:size-6" />
                    </div>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent>
                {answer && (
                  <div
                    className={cn(
                      'transition-all duration-500 overflow-hidden'
                    )}
                  >
                    <p
                      className={
                        'font-DM text-brand-Gray1 font-light text-xs em:text-base max-w-[900px] w-full px-[10px] sm:px-[30px] pb-[41px]'
                      }
                    >
                      {answer}
                    </p>
                  </div>
                )}
              </AccordionContent>
            </AccordionItem>
          );
        })}
    </Accordion>
  );
};

export default QuestionAndAnswers;
