'use client';
import React, { useState, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { LottieRefCurrentProps } from 'lottie-react';
import useIntersectionObserver from '@/app/(landing)/lib/useIntersectionObserver';
const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export interface LottieCardProps {
  jsonFileUrl: object | string;
  className: string;
  autoPlay?: boolean;
  loop?: boolean;
  speed?: number;
  selectedIndex?: number;
}

const LottieCard: React.FC<LottieCardProps> = ({
  jsonFileUrl,
  className,
  autoPlay = false,
  loop = false,
  speed = 3
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const isVisible = useIntersectionObserver(targetRef, {
    threshold: 0.5
  });

  useEffect(() => {
    if (isVisible) {
      setIsInView(true);
    }
  }, [isVisible]);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(speed);
      const direction = isHovered ? 1 : -1;
      lottieRef.current?.setDirection(direction);
      lottieRef.current?.play();
    }
  }, [isHovered, speed]);

  return (
    <div
      ref={targetRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`${className} transition-opacity duration-300`}
    >
      {isInView && (
        <Lottie
          lottieRef={lottieRef}
          animationData={jsonFileUrl}
          autoPlay={autoPlay}
          loop={loop}
          className={className}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice'
          }}
        />
      )}
    </div>
  );
};

export default LottieCard;
