'use client';
import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { Spinner } from '@/components/ui/spinner';

const ReactPlayer = dynamic(() => import('react-player/youtube'), {
  ssr: false,
});

type VideoDialogProps = {
  youtubeUrl: string;
  children: React.ReactNode;
};

export const VideoDialog = ({ youtubeUrl, children }: VideoDialogProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [youtubeUrl]);

  const handleReady = () => {
    setLoading(false);
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      setLoading(true);
    }
  };

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>

      <DialogContent className="w-full max-w-[95%] sm:max-w-[90%] lg:max-w-[1180px] lg:p-10 p-8 rounded-lg">
        <div className="relative w-full aspect-[16/9] flex items-center justify-center bg-black">
          {loading && <Spinner className="absolute z-10" />}
          <ReactPlayer
            url={youtubeUrl}
            width="100%"
            height="100%"
            controls
            playing
            onReady={handleReady}
            className="z-20"
            config={{
              playerVars: {
                cc_load_policy: 1,
              },
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
