'use client';
import React from 'react';
import clsx from 'clsx';
import { keyFeaturesSectionType } from '@/app/(landing)/lib/types/sectionsType';
import Image from '../Image';
import LottieCard from '../Common/LottieCard';

const KeyFeaturesSection = (block: keyFeaturesSectionType) => {
  const { iconTitleContentLotties, title } = block || {};

  const colSpanClass = ({ index }: { index: number }) => {
    return clsx({
      'md:col-span-12 xl:col-span-9': index === 0,
      'md:col-span-6 xl:col-span-3 xl:row-span-2 col-start-1 xl:col-start-10':
        index === 1,
      'md:col-span-6 xl:col-span-3 xl:row-span-2': index === 2,
      'md:col-span-6': index === 3,
      'md:col-start-7 md:col-span-6 xl:col-span-4': index === 4,
      'md:col-start-1 md:col-span-12 xl:col-span-5': index === 5,
    });
  };

  const imageSizeClass = ({ index }: { index: number }) => {
    return clsx({
      'h-full xl:h-[300px] max-w-full xl:max-w-[700px]': index === 0,
      'h-[300px] md:h-[530px] max-w-[300px]  md:max-w-[320px] xl:max-w-[320px] xl:absolute top-0 -z-10':
        index === 1,
      '': index === 2,
      'h-[350px] md:h-[370px] max-w-[350px] md:max-w-[350px] xl:max-w-[380px] xl:absolute -right-12  top-0 -z-10':
        index === 3,
      'h-[330px] max-w-[350px] xl:absolute right-0 top-0 -z-10': index === 4,
      'h-auto md:h-[300px] max-w-[600px] xl:absolute right-0 top-0 -z-10':
        index === 5,
    });
  };

  const maxWidthClass = ({ index }: { index: number }) => {
    return clsx({
      'xl:max-w-[364px]': index === 0,
      'xl:max-w-[241px]': index === 1,
      'xl:max-w-[254px]': index === 2,
      'xl:max-w-[370px]': index === 3,
      'xl:max-w-[350px]': index === 4,
      'xl:max-w-[299px]': index === 5,
    });
  };

  const lottieSizeDesktop = ({ index }: { index: number }) => {
    return clsx({
      'hidden xl:block [&>svg]:absolute [&>svg]:translate-x-[-50%] [&>svg]:right-0 em:[&>svg]:right-[0%]  xl:[&>svg]:right-[3rem]  md:[&>svg]:!h-[300px] [&>svg]:max-w-[900px] xl:[&>svg]:max-w-[55%] [&>svg]:w-full w-full max-w-[900px] xl:max-w-[540px] h-[250px] md:h-[60%] [&>svg]:right-0 [&>svg]:top-[33px]  ':
        index === 0,
      'hidden xl:block [&>svg]:w-full [&>svg]:!h-[400px] [&>svg]:absolute ':
        index === 1,
      'hidden xl:block': index === 2,
      'hidden xl:block [&>svg]:!h-[340px] [&>svg]:max-w-[350px] xl:[&>svg]:max-w-[250px] w-full max-w-[330px] absolute right-[-5%] top-0':
        index === 3,
      'hidden xl:block [&>svg]:!h-[320px] [&>svg]:max-w-[330px] h-[500px] max-w-[900px] absolute right-[0%] top-0 xl:[&>svg]:absolute [&>svg]:right-0 [&>svg]:top-0 z-10':
        index === 4,
      'hidden xl:block [&>svg]:h-auto  md:[&>svg]:!h-[367px] [&>svg]:max-w-[80%] [&>svg]:!h-[372px] max-w-[900px] xl:absolute right-0 top-0  [&>svg]:absolute [&>svg]:right-0 [&>svg]:top-0 ':
        index === 5,
    });
  };
  const lottieSizeMobile = ({ index }: { index: number }) => {
    return clsx({
      '[&>svg]:w-full [&>svg]:max-w-[500px] [&>svg]:!h-auto [&>svg]:mx-auto px-4':
        index === 0,
      '[&>svg]:w-full [&>svg]:max-w-[300px] [&>svg]:!h-auto [&>svg]:mx-auto':
        index === 1,
      '[&>svg]:!h-[320px] esm:[&>svg]:!h-[400px]  [&>svg]:max-w-[400px]  [&>svg]:mx-auto':
        index === 2,
      '[&>svg]:!h-[250px] em:[&>svg]:!h-[340px] [&>svg]:max-w-[450px] [&>svg]:mx-auto ':
        index === 3,
      '[&>svg]:!h-[280px] em:[&>svg]:!h-[320px] [&>svg]:max-w-[400px]  [&>svg]:mx-auto ':
        index === 4,
      '[&>svg]:h-auto  md:[&>svg]:!h-[280px] [&>svg]:max-w-[400px] [&>svg]:mx-auto ':
        index === 5,
    });
  };
  const vactorImageClass = ({ index }: { index: number }) => {
    return clsx({
      'bg-vectorBgImage bg-[right_center]':
        index === 0 || index === 3 || index === 4 || index === 5,
      'bg-vector2BgImage bg-[top_center]': index === 1 || index === 2,
    });
  };

  return (
    <div className="pb-[100px] lg:pb-[137px] ">
      <div className="container">
        <h2 className="text-[44px] lg:text-[54px] text-center">{title}</h2>
        {iconTitleContentLotties && (
          <div className="grid grid-cols-1 md:grid-cols-12 gap-5 mt-8 em:mt-10 lg:mt-[64px]">
            {iconTitleContentLotties?.map((item, index) => {
              const { icon, content, title, imagePostion, vectroImage, media } =
                item || {};

              return (
                <div
                  key={index}
                  className={clsx(
                    colSpanClass({ index }),
                    'flex flex-col xl:flex-row relative items-center justify-between bg-brand-GrayMain rounded-lg w-full overflow-hidden border-[1px] border-[#E9EAEB]  z-10 h-full bg-contain bg-no-repeat pt-8',
                    imagePostion === 'right' ? '' : ' xl:flex-col-reverse',
                    vactorImageClass({ index })
                  )}
                >
                  <div className="pl-[30px] xl:pl-[50px] pr-[30px] sm:pr-4 pb-12 pt-[30px] sm:pt-[60px]">
                    {icon && (
                      <div className="flex justify-center md:justify-start">
                        <Image
                          src={icon}
                          alt={icon?.alt}
                          width={40}
                          height={40}
                          className="min-w-[40px] h-[40px]"
                        />
                      </div>
                    )}
                    {title && (
                      <h5 className="PrimarySubtitle font-semibold mt-5 md:mt-[30px] mb-2.5 text-center md:text-left">
                        {title}
                      </h5>
                    )}
                    {content && (
                      <p
                        className={clsx(
                          'text-sm em:text-base text-nblack font-extralight w-full text-center md:text-left',
                          maxWidthClass({ index })
                        )}
                      >
                        {content}
                      </p>
                    )}
                  </div>

                  {/* Desktop Media */}
                  {media?.deskTopMedia?._type === 'image' &&
                    media?.deskTopMedia?.url && (
                      <div
                        className={clsx(
                          'w-full hidden xl:block',
                          imageSizeClass({ index })
                        )}
                      >
                        <Image
                          src={media?.deskTopMedia}
                          alt={media?.deskTopMedia?.alt}
                          className={clsx(
                            'w-full object-cover mx-auto ',
                            imageSizeClass({ index })
                          )}
                        />
                      </div>
                    )}
                  {media?.deskTopMedia?._type === 'lottie' &&
                    media?.deskTopMedia?.url && (
                      <LottieCard
                        className={clsx(
                          'w-full ',
                          lottieSizeDesktop({ index })
                        )}
                        jsonFileUrl={media?.deskTopMedia?.url}
                        autoPlay={false}
                        loop={false}
                      />
                    )}

                  {/* Mobile Media */}
                  {media?.mobileMedia?._type === 'image' &&
                    media?.mobileMedia?.url && (
                      <div
                        className={clsx(
                          'w-full block xl:hidden',
                          imageSizeClass({ index })
                        )}
                      >
                        <Image
                          src={media?.mobileMedia}
                          alt={media?.mobileMedia?.alt}
                          className={clsx(
                            'w-full object-cover mx-auto ',
                            imageSizeClass({ index })
                          )}
                        />
                      </div>
                    )}
                  {media?.mobileMedia?._type === 'lottie' &&
                    media?.mobileMedia?.url && (
                      <div className={clsx('w-full block xl:hidden')}>
                        <LottieCard
                          className={clsx('', lottieSizeMobile({ index }))}
                          jsonFileUrl={media?.mobileMedia?.url}
                          autoPlay={false}
                          loop={false}
                        />
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default KeyFeaturesSection;
