import React, { ReactNode } from 'react'

const BlockQuote = ({children}:{children:ReactNode}) => {
  return (
    <blockquote className='pl-4 border-l-[4px] border-brand-SlateGray my-4'>
      {children}
    </blockquote>
  )
}

export default BlockQuote
