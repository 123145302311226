'use client';
import React from 'react';

import DeskTopView from './deskTopView';
import MobileView from './mobileView';
import { HeaderType } from '@/app/(landing)/lib/types/header';
import clsx from 'clsx';

export interface HeaderProps {
  block: HeaderType;
  path: string;
}
const Header: React.FC<HeaderProps> = ({ block, path }) => {
  const [toggle, setToggle] = React.useState(false);

  React.useEffect(() => {
    if (toggle) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle((prevToggle) => !prevToggle);
  };

  return (
    <div
      id="header"
      className={clsx(
        'py-5 fixed left-0 right-0 z-10 w-full bg-white bg-opacity-60 backdrop-blur z-20',
      )}
    >
      <div className="container">
        <DeskTopView block={block} path={path} />
        <MobileView
          block={block}
          path={path}
          handleToggle={handleToggle}
          toggle={toggle}
        />
      </div>
    </div>
  );
};

export default Header;
