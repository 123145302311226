import { getClient } from '../getClient'

export type FetchSanityQuery = {
  groqQuery: string
}

type FetchSanityQueryParams = { [key: string]: unknown }

type FetchSanityParams = {
  query: FetchSanityQuery
  queryParams?: FetchSanityQueryParams
  revalidate?: number | false
}

export const fetchDataFromSanity = async <T = unknown>({
  query,
  queryParams = {},
  revalidate = 60,

}: FetchSanityParams): Promise<T | undefined> => {
  const { groqQuery } = query
  const client = getClient()
  try {
    const data = await client.fetch<T>(groqQuery, queryParams, { next: { revalidate: revalidate } })
    return data
  } catch (error) {
    console.log(error);
  }
}
