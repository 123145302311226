import { CustomImageType } from '@/app/(landing)/lib/types/common';
import { ImageType } from '@/app/(landing)/lib/types/image';
import { default as NextImage, ImageProps } from 'next/image';
import SanityImage from '../SanityImage';

const Image: React.FC<
  Omit<ImageProps, 'src' | 'alt'> & {
    src: string | ImageType | CustomImageType;
    alt: string | undefined;
  }
> = ({ src, alt, ...props }) => {
  if (typeof src === 'object') {
    if ('url' in src) {
      return (
        <NextImage
          src={src.url}
          alt={alt || 'Image'}
          quality={100}
          width={src.width || 100}
          height={src.height || 100}
          {...props}
        />
      );
    } else {
      return <SanityImage src={src} {...props} />;
    }
  }
  return <NextImage src={src} alt={alt || 'Image'} quality={100} {...props} />;
};
export default Image;
