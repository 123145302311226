import { groq } from "next-sanity";

export const settingsFields = groq`
_id,
_type,
"navlinks": sidebar.pages[]->{
_id,
_type,
title,
"slug":slug.current
}
`
export const sidebarFields = /* groq */ `*[_type=="settings"][0]{
${settingsFields}
}`;
