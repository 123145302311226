import { useEffect, useState } from 'react';

type IntersectionObserverArgs = {
    threshold?: number | number[];
    root?: Element | null;
    rootMargin?: string;
};

const useIntersectionObserver = (
    targetRef: React.RefObject<Element>,
    { threshold = 0, root = null, rootMargin = '0px' }: IntersectionObserverArgs = {}
): boolean => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { threshold, root, rootMargin }
        );

        const target = targetRef.current;
        if (target) {
            observer.observe(target);
        }

        return () => {
            if (target) observer.unobserve(target);
            observer.disconnect();
        };
    }, [targetRef, threshold, root, rootMargin]);

    return isIntersecting;
};

export default useIntersectionObserver;
