import { groq } from "next-sanity";
import { pageBuilder } from "../helperQueries";


const groqQuery = groq`*[_type == "page" && slug.current==$slug]{
_type,
_id,
"slug":slug.current,
${pageBuilder},
}`;


export const allPageSlugsQuery = groq`
*[_type == "page" && defined(slug.current) && !(slug.current in ['landing'])][].slug.current`;

export const query = {
  groqQuery,
  allPageSlugsQuery
};
