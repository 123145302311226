import React, { useState, useEffect, useRef, useMemo } from 'react';
import Image from 'next/image';
import featurePromptsData from './featurePrompts.json';
import {
  buildFeature,
  generateSyntheticInputs,
  queryFeature,
  BuildResponse,
  QueryResponse,
} from './demoAPI';

const LoadingSpinnerSlide = ({ isActive }: { isActive: boolean }) => {
  // Steps with different durations
  const steps = [
    { label: 'Building feature', duration: 900 },
    { label: 'Comparing models', duration: 600 },
    { label: 'Creating schema', duration: 700 },
    { label: 'Deploying endpoint', duration: 500 },
    // { label: 'AI Function ready', duration: 1000 },
    { label: 'Creating examples', duration: 12000 },
  ];

  // Index of the current step
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    setCurrentStepIndex(0);
  }, [isActive]);

  // Advance the step after each duration
  useEffect(() => {
    if (!isActive) return;

    const timer = setTimeout(() => {
      setCurrentStepIndex((prev) => (prev + 1) % steps.length);
    }, steps[currentStepIndex].duration);

    return () => clearTimeout(timer);
  }, [isActive, currentStepIndex, steps]);

  // Container and step sizing
  const containerHeight = 220; // total height of the "viewing window" (px)
  const stepHeight = 56;       // each step container is h-14 in Tailwind = 56px
  
  const translateY = 
    (containerHeight / 2) - (stepHeight / 2) - (currentStepIndex * stepHeight);

  return (
    <div
      className="relative flex flex-row items-center justify-center gap-8 p-4 bg-white rounded-lg shadow-xl shadow-gray-500/10"
      style={{ width: '100%', height: containerHeight }}
    >
      {/* ---- Spinner (fixed in place) ---- */}
      <div className="flex flex-col items-center justify-center h-full">
        {/* Thin gray spinner */}
        <div className="w-10 h-10 border-[3px] border-gray-500 border-t-transparent rounded-full animate-spin" />
      </div>

      {/* ---- Steps list ---- */}
      <div className="relative w-72 h-full overflow-hidden">
        {/* Top fade-out gradient */}
        <div className="pointer-events-none absolute top-0 left-0 w-full h-16 bg-gradient-to-b from-white to-transparent z-10" />
        {/* Bottom fade-out gradient */}
        <div className="pointer-events-none absolute bottom-0 left-0 w-full h-16 bg-gradient-to-t from-white to-transparent z-10" />

        {/* Scrolling container: moves up/down to center the current step */}
        <div
          className="absolute left-0 top-[-14px] w-full h-full transition-transform duration-300 ease-in-out"
          style={{
            transform: `translateY(${translateY}px)`,
          }}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              className={`
                flex items-center h-14 transition duration-500
                ${index === currentStepIndex ? 'text-gray-500' : 'text-gray-200'}
              `}
            >
              <span className="text-2xl lg:text-3xl font-regular">
                {step.label}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SchemaPreviewSlide = ({ schema }: { schema: BuildResponse | null }) => {
  const fieldNameStyles = 'font-semibold text-gray-800';
  const fieldStyles = 'bg-gray-50/80 backdrop-blur-sm p-3 rounded-lg shadow-md shadow-gray-500/10';
  const lineStyles = 'border-l-2 border-gray-200 ml-3 pl-3';
  const typeTagStyles = 'inline-flex items-center gap-1.5 bg-gray-100/70 text-gray-600 text-xs px-2.5 py-1 rounded-full';

  // Helper to render fields recursively with indentation and tree lines
  const renderSchemaFields = (node: any, level = 0) => {
    if (!node || typeof node !== 'object') return null;

    // If it's an object type, render its properties
    if (node.type === 'object' && node.properties) {
      const requiredFields = node.required || [];
      return Object.entries(node.properties).map(([fieldName, fieldVal]: any) => {
        const val = fieldVal || {};
        const fieldTypes = Array.isArray(val.type) ? val.type.join(' / ') : val.type || 'unknown';
        const isRequired = requiredFields.includes(fieldName);
        const hasEnum = val.enum && val.enum.length > 0;
        
        return (
          <div key={fieldName} className={level > 0 ? lineStyles : ''} style={{ marginLeft: level * 24 }}>
            <div className={`flex flex-col mb-3 ${fieldStyles}`}>
              <div className="flex items-center gap-2.5 flex-wrap">
                <span className={fieldNameStyles}>{fieldName}</span>
                <span className={typeTagStyles}>
                  <Image
                    src="/images/type-icon.svg"
                    alt="Type icon"
                    width={12}
                    height={12}
                  />
                  {fieldTypes}
                </span>
                {isRequired && (
                  <span className="text-orange-600/60 text-xs rounded-full">
                    *
                  </span>
                )}
                {hasEnum && (
                  <span className="inline-flex items-center gap-1.5 bg-blue-50 text-blue-600 text-xs px-2.5 py-1 rounded-full">
                    enum: {val.enum.map((e: any) => e === null ? 'null' : e).join(', ')}
                  </span>
                )}
              </div>
              <span className="text-gray-500 text-sm mt-1">
                {val.description || val.title || 'No description'}
              </span>
            </div>
            {/* Recursively render child fields if any */}
            {renderSchemaFields(val, level + 1)}
          </div>
        );
      });
    }

    // If it's an array type, handle "items"
    if (node.type === 'array' && node.items) {
      return (
        <div className={level > 0 ? lineStyles : ''} style={{ marginLeft: level * 24 }}>
          <div className={`mb-3 ${fieldStyles}`}>
            <div className="flex items-center gap-2.5">
              <span className={`text-gray-500`}>items</span>
              <span className={typeTagStyles}>
                <Image
                  src="/images/type-icon.svg"
                  alt="Type icon"
                  width={12}
                  height={12}
                />
                {Array.isArray(node.items.type)
                  ? node.items.type.join(' / ')
                  : node.items.type || 'unknown'}
              </span>
            </div>
          </div>
          {renderSchemaFields(node.items, level + 1)}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      {schema && schema.output_schema ? (
        <div className="p-4 mb-4 w-full max-w-xl bg-white/90 backdrop-blur-sm rounded-lg shadow-xl shadow-gray-500/10">
          <h4 className="font-semibold text-gray-800 mb-2">Let's use this format:</h4>
          <div className="p-3 rounded text-sm text-left">
            {renderSchemaFields(schema.output_schema)}
          </div>
          <div className="flex items-center justify-center gap-2 text-gray-500 text-sm mb-2">
            <div className="w-4 h-4 border-2 border-[#FF5B45] border-t-transparent rounded-full animate-spin" />
            Generating example inputs...
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full">
          <p className="text-xl text-gray-400">Schema Preview goes here...</p>
        </div>
      )}
    </div>
  );
};

// Helper function to syntax highlight JSON
const formatJSON = (obj: any) => {
  const jsonStyles = 'font-mono text-sm';
  return JSON.stringify(obj, null, 2)
    .split('\n')
    .map(line => {
      // 1) Highlight braces and brackets
      line = line.replace(/[{}[\]]/g, match => {
        return `<span class='text-gray-600/50'>${match}</span>`;
      });

      // 2) Highlight commas
      line = line.replace(/,/g, match => {
        return `<span class='text-gray-400'>${match}</span>`;
      });

      // 3) Highlight ALL remaining strings 
      //    (which will be array items, or string values)
      line = line.replace(
        /"([^"]+)"/g,
        `<span class='${jsonStyles} text-green-600'>"$1"</span>`
      );

      // 4) Override string highlighting for keys (the stuff before a colon)
      //    e.g. `"someKey":`
      line = line.replace(
        /<span class='[^']+'>("[^"]+")<\/span>:/g,
        `<span class='${jsonStyles} text-blue-600'>$1</span>:`
      );

      // 5) Highlight numbers
      //    e.g. `: 123`
      line = line.replace(
        /: (\d+)/g,
        `: <span class='${jsonStyles} text-orange-600'>$1</span>`
      );

      // 6) Highlight booleans and null
      //    e.g. `: true`
      line = line.replace(
        /: (true|false|null)/g,
        `: <span class='${jsonStyles} text-purple-600'>$1</span>`
      );

      return line;
    })
    .join('\n');
};  


// Start of Selection
const AIFeatureExampleSlide = ({
  exampleQueries,
  functionName,
  buildDuration,
}: {
  exampleQueries: { input: string; response: QueryResponse }[];
  functionName: string;
  buildDuration: number;
}) => {
  // If no example queries, show placeholder message
  if (!exampleQueries || exampleQueries.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center w-full">
        <p className="text-xl font-semibold mb-4">
          Example Generated AI Feature
        </p>
        <div className="text-gray-700">No example queries yet...</div>
      </div>
    );
  }

  // Index of the current query example
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0);
  // Track how far we've scrolled
  const [scrollPosition, setScrollPosition] = useState(0);

  // Duration each example remains in view before transitioning to the next
  const DURATION_PER_EXAMPLE = 3500;
  // Interval and step for incremental scrolling
  const SCROLL_INTERVAL = 50; // Update scroll every 50ms
  const SCROLL_STEP = 1; // Pixels to scroll per interval

  useEffect(() => {
    // Reset scroll position for the new example
    setScrollPosition(0);

    // Wait for the next example
    const timer = setTimeout(() => {
      setCurrentExampleIndex((prev) => (prev + 1) % exampleQueries.length);
    }, DURATION_PER_EXAMPLE);

    return () => clearTimeout(timer);
  }, [currentExampleIndex, exampleQueries]);

  useEffect(() => {
    // Smooth scrolling effect
    const scrollTimer = setInterval(() => {
      setScrollPosition((prev) => {
        const inputContainer = document.querySelector(
          `[data-example="${currentExampleIndex}"] .input-content`
        ) as HTMLElement | null;
        const outputContainer = document.querySelector(
          `[data-example="${currentExampleIndex}"] .output-content`
        ) as HTMLElement | null;

        if (!inputContainer || !outputContainer) return prev;

        // Calculate max scroll needed for both input and output
        const maxScroll = Math.max(
          inputContainer.scrollHeight - inputContainer.clientHeight,
          outputContainer.scrollHeight - outputContainer.clientHeight
        );

        // If we've reached the bottom, stay there
        if (prev >= maxScroll) return maxScroll;
        return prev + SCROLL_STEP;
      });
    }, SCROLL_INTERVAL);

    return () => clearInterval(scrollTimer);
  }, [currentExampleIndex]);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <p className="text-xl font-semibold mb-4">
        {functionName ? (
          <>
            <code className="bg-[#FF5B45]/10 text-[#FF5B45] px-1 py-0.5 rounded font-medium font-mono">{functionName}</code> feature built (in {buildDuration}s)
          </>
        ) : `AI Function built (in ${buildDuration}s)`}
      </p>

      {/* Container to hold one example at a time, top-aligned, cross-fading */}
      <div
        className="relative w-full max-w-2xl bg-white rounded-lg shadow-xl shadow-gray-500/10 overflow-hidden h-[450px] lg:h-[220px]"
      >
        {exampleQueries.map(({ input, response }, idx) => (
          <div
            key={idx}
            data-example={idx}
            className={`
              absolute top-0 left-0 w-full h-full
              transition-opacity duration-500
              flex items-start justify-center
              ${idx === currentExampleIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'}
            `}
          >
            <div 
              className="bg-gray-50 w-full p-4 rounded-lg shadow-md flex flex-col lg:flex-row gap-4 cursor-pointer group"
              onClick={() => window.location.href = '/signup'}
            >
              {/* Test Input container */}
              <div className="flex-1 border rounded-lg p-3 bg-white overflow-hidden relative">
                <p className="font-bold sticky top-0 bg-white z-10 mb-2">Example Input</p>
                <div className="overflow-hidden h-[150px]">
                  <p
                    className="text-left input-content"
                    style={{
                      transform: `translateY(-${scrollPosition}px)`,
                      transition: 'transform 0.5s ease-out',
                    }}
                  >
                    {input}
                  </p>
                </div>
              </div>

              {/* Output container */}
              <div className="flex-1 border rounded-lg p-3 bg-white overflow-hidden relative">
                <p className="font-bold sticky top-0 bg-white z-10 mb-2">Output</p>
                <div className="overflow-hidden h-[140px]">
                  <pre
                    className="bg-gray-50 p-2 rounded text-sm font-mono whitespace-pre-wrap text-left output-content"
                    style={{
                      transform: `translateY(-${scrollPosition}px)`,
                      transition: 'transform 0.5s ease-out',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: formatJSON(response.response),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* Gradient overlay with CTA */}
        <div
          className="absolute bottom-0 left-0 w-full h-[150px] flex items-end justify-center z-20"
          style={{
            background:
              'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,.9) 75%, rgba(255,255,255,1) 100%)',
          }}
        >
          <button
            onClick={() => window.location.href = '/signup'}
            className="mb-4 bg-[#FF5B45] hover:bg-[#E64E3E] text-white font-bold px-[22px] py-[7px] rounded-[28px] transition-colors duration-200 flex items-center"
          >
            <Image
              src="/images/ship-it.svg"
              alt="Arrow icon"
              width={20}
              height={20}
              className="mr-2"
            />
            Start Testing
          </button>
        </div>
      </div>
    </div>
  );
};

const FeatureButton = ({
  icon,
  label,
  onClick,
  color = 'gray',
}: {
  icon: string;
  label: string;
  onClick: () => void;
  color?: string;
}) => {
  // Simplified to only use gray
  const colorClasses = 'text-gray-500 border-gray-200 hover:bg-gray-50';

  return (
    <button
      onClick={onClick}
      className={`border font-regular py-1 px-2 rounded inline-flex items-center text-[13px] transition-colors duration-150 ${colorClasses}`}
    >
      <span className="mr-2">{icon}</span>
      <span>{label}</span>
    </button>
  );
};

const parseSpanTags = (str: string) => {
  const spanTagRegex = /(<span[^>]*>|<\/span>)/g;
  const tokens: { type: 'text' | 'tag'; content: string }[] = [];

  let lastIndex = 0;
  let match;

  while ((match = spanTagRegex.exec(str)) !== null) {
    // Everything between lastIndex and match.index is text
    if (match.index > lastIndex) {
      tokens.push({
        type: 'text',
        content: str.slice(lastIndex, match.index),
      });
    }
    // The matched tag
    tokens.push({
      type: 'tag',
      content: match[0],
    });
    lastIndex = spanTagRegex.lastIndex;
  }

  // Remainder of the string after the last tag
  if (lastIndex < str.length) {
    tokens.push({
      type: 'text',
      content: str.slice(lastIndex),
    });
  }

  return tokens;
};

const tokensToHTML = (typedTokens: { type: 'text' | 'tag'; content: string }[]) => {
  return typedTokens.map(t => t.content).join('');
};

const PromptInputSlide = ({
  prompt,
  setPrompt,
  onShipFeature,
  isShipping,
}: {
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  onShipFeature: () => void;
  isShipping: boolean;
}) => {
  const [isPromptFocused, setIsPromptFocused] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const typedPlaceholders = useMemo(() => {
    const rawPrompts = featurePromptsData
      .reduce<string[]>((acc, feature) => acc.concat(feature.featurePrompts), [])
      .sort(() => Math.random() - 0.5);

    // typedPlaceholders is now Array of arrays of typed tokens
    return rawPrompts.map(parseSpanTags);
  }, [featurePromptsData]);

  useEffect(() => {
    if (!typedPlaceholders.length) return;

    let timer: ReturnType<typeof setTimeout>;
    let currentPlaceholderIndex = 0;
    let typedTokens: { type: 'text' | 'tag'; content: string }[] = [];
    let tokenIndex = 0;
    let charIndex = 0;
    let phase: 'TYPING' | 'HOLD' | 'DELETING' = 'TYPING';
    const isPaused = prompt.length > 0;

    const TYPING_SPEED = 20;
    const DELETING_SPEED = 5;
    const HOLD_DURATION = 2000;

    const getCurrentTokens = () => {
      return typedPlaceholders[currentPlaceholderIndex];
    };

    const updatePlaceholderText = () => {
      setPlaceholderText(tokensToHTML(typedTokens));
    };

    const typeNextCharacter = () => {
      const tokens = getCurrentTokens();
      if (tokenIndex >= tokens.length) {
        phase = 'HOLD';
        timer = setTimeout(tick, HOLD_DURATION);
        return;
      }
      const currentToken = tokens[tokenIndex];
      if (currentToken.type === 'tag') {
        typedTokens.push(currentToken);
        tokenIndex++;
        charIndex = 0;
        updatePlaceholderText();
        timer = setTimeout(tick, TYPING_SPEED);
      } else {
        const textContent = currentToken.content;
        const nextChar = textContent.charAt(charIndex);
        if (nextChar) {
          if (charIndex === 0) {
            typedTokens.push({ type: 'text', content: '' });
          }
          typedTokens[typedTokens.length - 1].content += nextChar;
          charIndex++;
          updatePlaceholderText();
          timer = setTimeout(tick, TYPING_SPEED);
        } else {
          tokenIndex++;
          charIndex = 0;
          timer = setTimeout(tick, TYPING_SPEED);
        }
      }
    };

    const backspaceOneCharacter = () => {
      if (typedTokens.length === 0) {
        currentPlaceholderIndex = (currentPlaceholderIndex + 1) % typedPlaceholders.length;
        tokenIndex = 0;
        charIndex = 0;
        phase = 'TYPING';
        timer = setTimeout(tick, TYPING_SPEED);
        return;
      }
      const lastToken = typedTokens[typedTokens.length - 1];
      if (lastToken.type === 'tag') {
        typedTokens.pop();
        updatePlaceholderText();
        timer = setTimeout(tick, DELETING_SPEED);
      } else {
        if (lastToken.content.length > 1) {
          lastToken.content = lastToken.content.slice(0, -1);
          updatePlaceholderText();
          timer = setTimeout(tick, DELETING_SPEED);
        } else {
          typedTokens.pop();
          updatePlaceholderText();
          timer = setTimeout(tick, DELETING_SPEED);
        }
      }
    };

    const tick = () => {
      if (isPaused) return;
      switch (phase) {
        case 'TYPING': {
          typeNextCharacter();
          break;
        }
        case 'HOLD': {
          if (tokenIndex >= getCurrentTokens().length) {
            phase = 'DELETING';
          } else {
            phase = 'TYPING';
          }
          tick();
          break;
        }
        case 'DELETING': {
          backspaceOneCharacter();
          break;
        }
      }
    };

    if (!isPaused) {
      tick();
    }

    return () => clearTimeout(timer);
  }, [typedPlaceholders, prompt]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const handleFeatureButtonClick = (featureType: string) => {
    const foundFeature = featurePromptsData.find(f => f.featureType === featureType);
    if (foundFeature) {
      const randomPrompt =
        foundFeature.featurePrompts[
          Math.floor(Math.random() * foundFeature.featurePrompts.length)
        ];
      const parsedTokens = parseSpanTags(randomPrompt);
      const textOnlyTokens = parsedTokens.filter(token => token.type === 'text');
      const noSpanPrompt = tokensToHTML(textOnlyTokens);
      setPrompt(noSpanPrompt);
    }
  };

  const isRunDisabled = isShipping || !prompt.trim();

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <style global jsx>{`
        #prompt-placeholder span {
          display: inline-block;
          box-shadow: 0 0 0px 2px rgba(0,0,0,0);
          border-radius: 0.375rem;
          padding: 0 2px;
          margin: -1px 0;
          position: relative;
          text-align: center;
        }

        #prompt-placeholder span:before {
          display: flex;
          align-items: center;
          justify-content: center;
          color: gray;
          position: absolute;
          background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
          left: 50%;
          transform: translateX(-50%);
          top: -10px;
          padding: 0 4px;
          font-size: 0.7em;
        }

        #prompt-placeholder .prompt-input {
          color: #93C5FD;
          box-shadow: 0 0 0px 2px rgba(147, 197, 253, 0.2);
        }

        #prompt-placeholder .prompt-output {
          color: #FCA5A5;
          box-shadow: 0 0 0px 2px rgba(252, 165, 165, 0.2);
        }

        #prompt-placeholder .prompt-mod {
          color: #6EE7B7;
        }

        #prompt-placeholder .prompt-input:before { content: 'input'; color: rgba(147, 197, 253, 0.9); }
        #prompt-placeholder .prompt-output:before { content: 'output'; color: rgba(252, 165, 165, 0.9); }
      `}</style>

      {/* Prompt Input */}
      <div
        id="prompt-area"
        className={`mt-1 block w-full bg-white border-2 rounded-xl overflow-hidden relative shadow-center ${
          isPromptFocused
            ? 'shadow-[#FF5B45]/30 shadow-2xl border-[#FF5B45]'
            : 'shadow-gray-500/10 shadow-xl border-gray-300'
        } transition duration-300`}
      >
        <textarea
          ref={textareaRef}
          name="prompt"
          className="w-full placeholder-gray-400 pt-4 pb-20 px-4 resize-none h-4 border-0 focus:ring-0 focus:outline-none text-brand-Black1"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          onFocus={() => setIsPromptFocused(true)}
          onBlur={() => setIsPromptFocused(false)}
        />
        {prompt === '' && (
          <span
            id="prompt-placeholder"
            className="absolute text-left text-gray-400 pointer-events-none select-none left-4 top-4 right-4"
            dangerouslySetInnerHTML={{ __html: placeholderText }}
          />
        )}
        <div className="flex flex-wrap gap-2 px-4 pb-4">
          {featurePromptsData.map((feature, index) => {
            const colors = ['blue', 'green', 'purple', 'pink', 'orange', 'teal'];
            const color = colors[index % colors.length]; // Cycle through colors

            return (
              <FeatureButton
                key={feature.featureType}
                icon={feature.featureIcon || '🔍'}
                label={feature.featureType}
                onClick={() => handleFeatureButtonClick(feature.featureType)}
                color={color}
              />
            );
          })}
        </div>
        <div className="mt-1 block w-full bg-white border-t border-gray-300 shadow-gray-500/10">
          <div className="flex justify-end px-4 py-3">
            <button
              className={`bg-[#FF5B45] ${!isRunDisabled ? 'lg:hover:bg-[#E64E3E]' : ''} text-white font-bold px-[22px] py-[7px] rounded-[28px] lg:transition lg:duration-200 disabled:opacity-70`}
              onClick={onShipFeature}
              disabled={isRunDisabled}
            >
              <span className="flex items-center">
                {isShipping ? (
                  <>
                    <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                      <circle 
                        className="opacity-25" 
                        cx="12" 
                        cy="12" 
                        r="10" 
                        stroke="currentColor" 
                        strokeWidth="4"
                        fill="none"
                      />
                      <path 
                        className="opacity-75" 
                        fill="currentColor" 
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Building...
                  </>
                ) : (
                  <>
                    <Image
                      src="/images/ship-it.svg"
                      alt="Arrow icon"
                      width={20}
                      height={20}
                      className="mr-2"
                    />
                    Create Feature
                  </>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DemoSlider = () => {
  const [prompt, setPrompt] = useState('');
  const [schema, setSchema] = useState<BuildResponse | null>(null);
  const [functionName, setFunctionName] = useState<string>('');
  const [buildDuration, setBuildDuration] = useState<number>(0);

  const [error, setError] = useState<string>('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Store individual queries for each example input
  const [exampleQueries, setExampleQueries] = useState<
    { input: string; response: QueryResponse }[]
  >([]);

  // Add new loading states
  const [isShipping, setIsShipping] = useState(false);
  const [isRunningTest, setIsRunningTest] = useState(false);

  // -----------------------------------------
  //  Track heights of each slide for dynamic resizing
  // -----------------------------------------
  const slidesRef = useRef<HTMLDivElement[]>([]);
  const [containerHeight, setContainerHeight] = useState<number | 'auto'>(
    'auto' // Start with 'auto' height
  );

  // If the current slide changes, update container height
  useEffect(() => {
    // Wait for next frame to ensure DOM has updated
    requestAnimationFrame(() => {
      if (slidesRef.current[currentSlide]) {
        const nextHeight = slidesRef.current[currentSlide].scrollHeight;
        setContainerHeight(nextHeight);
      }
    });
  }, [currentSlide, schema, exampleQueries]);

  const handlePrevSlide = () => {
    setIsTransitioning(true);
    setCurrentSlide((prev) => Math.max(prev - 1, 0));
  };

  const handleNextSlide = () => {
    setIsTransitioning(true);
    setCurrentSlide((prev) => Math.min(prev + 1, slides.length - 1));
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  const handleReset = () => {
    setCurrentSlide(0);
    setPrompt('');
    setSchema(null);
    setExampleQueries([]);
    setFunctionName('');
    setError('');
    setBuildDuration(0);
  };

  // Main feature-building function
  const handleBuild = async () => {
    try {
      const startTime = performance.now();
      setIsShipping(true);
      setError('');
      setSchema(null);
      setExampleQueries([]);
      setFunctionName('');

      // Advance to loading spinner slide after a short delay
      setTimeout(() => {
        setCurrentSlide(1);
      }, 1500);

      console.log('Building feature with prompt:', prompt);

      const buildRes = await buildFeature(prompt, true);
      console.log(buildRes);
      setSchema(buildRes);
      
      // Format the function name
      const formattedName = buildRes.function_name
        .replace(/-[^-]+$/, '') // Remove last dash and everything after it
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2') // Handle acronyms: ROIAnalyzer -> ROI-Analyzer
        .replace(/([a-z\d])([A-Z])/g, '$1-$2') // Add dash before other uppercase letters
        .trim(); // Remove any leading/trailing spaces
      setFunctionName(formattedName);
      
      console.log('Function name:', formattedName);
      
      // Move to schema slide before generating examples
      setTimeout(() => {
        setCurrentSlide(2);
      }, 2000);

      const buildDurationSecs = ((performance.now() - startTime) / 1000).toFixed(1);
      setBuildDuration(parseFloat(buildDurationSecs));

      const genRes = await generateSyntheticInputs(buildRes.function_name, 3);
      console.log(genRes);

      // For each synthetic input, do a query
      const queries: { input: string; response: QueryResponse }[] = [];
      for (const input of genRes.example_inputs) {
        const res = await queryFeature(buildRes.function_name, input);
        console.log(res);
        queries.push({ input, response: res });
        setExampleQueries([...queries]); // Update example queries after each push
        
        if (queries.length === 1) {
          setCurrentSlide(3); // Move to AI Function slide after first query
        }
      }

    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsShipping(false);
    }
  };

  const handleTestSuite = async () => {
    try {
      setIsRunningTest(true);
      setError('');
      setSchema(null);
      setExampleQueries([]);
      setFunctionName('');

      const testPrompt = 'From these client success stories, identify critical success factors.';
      console.log('Running test suite with prompt:', testPrompt);
      const buildRes = await buildFeature(testPrompt, true);
      console.log('Build response:', buildRes);
      setFunctionName(buildRes.function_name);

      // Generate Synthetic Inputs
      const genRes = await generateSyntheticInputs(buildRes.function_name, 3);
      console.log('Generate synthetic inputs response:', genRes);

      // Query for each generated input
      const queries: { input: string; response: QueryResponse }[] = [];
      for (const input of genRes.example_inputs) {
        const res = await queryFeature(buildRes.function_name, input);
        queries.push({ input, response: res });
      }

      // Update state
      setSchema(buildRes);
      setExampleQueries(queries);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsRunningTest(false);
    }
  };

  const slides = [
    <PromptInputSlide 
      key="slide1" 
      prompt={prompt} 
      setPrompt={setPrompt}
      onShipFeature={handleBuild}
      isShipping={isShipping}
    />,
    <LoadingSpinnerSlide key="slide2" isActive={currentSlide === 1} />,
    <SchemaPreviewSlide key="slide3" schema={schema} />,
    <AIFeatureExampleSlide 
      key="slide4" 
      exampleQueries={exampleQueries} 
      functionName={functionName}
      buildDuration={buildDuration}
    />,
  ];

  // Add a new state to track initial load
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);

  // Add effect to set initial load after component mounts
  useEffect(() => {
    setHasInitiallyLoaded(true);
  }, []);

  return (
    <div
      className="relative w-full max-w-[88vw] lg:max-w-[760px] mx-auto mt-4 lg:overflow-visible overflow-hidden"
      style={{
        height: containerHeight,
        transition: containerHeight === 'auto' ? 'none' : 'height 0.3s ease-in-out',
      }}
    >
      <div
        className="flex w-full"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
          transition: 'transform 0.3s ease-in-out',
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        {slides.map((slide, idx) => (
          <div
            key={idx}
            ref={(el) => {
              if (el) slidesRef.current[idx] = el;
            }}
            style={{
              // Only apply height: 0 after initial load
              height: !hasInitiallyLoaded || idx === currentSlide ? 'auto' : 0
            }}
            className={`w-full flex-shrink-0 transition-opacity duration-300 ${
              idx === currentSlide ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {slide}
          </div>
        ))}
      </div>

      {/* Prev button */}
      {/* <button
        type="button"
        onClick={handlePrevSlide}
        disabled={currentSlide === 0 || isTransitioning}
        className="absolute top-1/2 left-[-10px] -translate-y-1/2 bg-white
                   rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors
                   disabled:opacity-50 disabled:cursor-not-allowed"
      >
        ‹
      </button> */}

      {/* Next button */}
      {/* <button
        type="button"
        onClick={handleNextSlide}
        disabled={currentSlide === slides.length - 1 || isTransitioning}
        className="absolute top-1/2 right-[-10px] -translate-y-1/2 bg-white
                   rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors
                   disabled:opacity-50 disabled:cursor-not-allowed"
      >
        ›
      </button> */}

      {/* Back button - only show on last slide */}
      {currentSlide === slides.length - 1 && (
        <button
          onClick={handleReset}
          className="absolute top-0 left-0 w-8 h-8 hidden lg:flex items-center justify-center bg-transparent hover:bg-gray-100/50 text-gray-500/70 hover:text-gray-500 rounded-full transition-colors duration-200"
        >
          ←
        </button>
      )}

      {error && <div className="text-red-600 mt-2">Error: {error}</div>}
    </div>
  );
};

export default DemoSlider;
