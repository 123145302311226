import { LinkType } from '../../types/linkType';
import { resolveLink } from '../resolveLink';

export enum LINK_TYPES {
  externalReference = 'externalReference',
  reference = 'reference',
  sectionReference = 'sectionReference'
}

export const resolveUrl = (link: LinkType) => {
  switch (link?.type) {
    case LINK_TYPES?.sectionReference:
      return (link?.link || link?.url) ?? '#';
    case LINK_TYPES?.reference:
      return resolveLink({ slug: link?.slug, type: link?.pageType });
    default:
      return (link?.link || link?.url) ?? '#';
    // TODO: currently this is a hack to make the link work. The schema on sanity is not consistent with the local schema. The remote schema uses the link field and the local schema uses the url field.
    // TODO: we need to fix this by making the schema consistent and then updating the resolveUrl function to use the correct field.
  }
};
