import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-white hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'text-secondary-foreground hover:bg-secondary/80 [box-shadow:hsl(var(--secondary))_0_-0.5px_0_0_inset] border border-input bg-muted',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        accent: 'bg-wecopink/90 text-primary-foreground hover:bg-wecopink/80',
        gray: 'hover:text-white hover:bg-black bg-transparent rounded-[6px] w-full py-3 px-5 font-medium border-[1px] hover:border-transparent border-black text-black BasicTransition',
        black:
          'text-white bg-black hover:bg-transparent rounded-[6px] w-full py-3 px-5 font-medium border-[1px] border-transparent hover:border-black hover:text-black BasicTransition',
        slim: 'py-1 px-2 text-sm border-none rounded-md',
        flat: 'bg-transparent border border-gray-300 rounded-md',
        orange:
          'py-3 px-[30px] bg-brand-Orange rounded-lg text-[#FDFDFD] text-base font-medium font-Poppins hover:bg-brand-Orange1',
        white:
          'bg-white py-[10px] px-10 text-lg text-brand-Orange font-medium rounded-[6px] hover:bg-transparent hover:text-white border-[1px] border-white BasicTransition',
        transparent:
          'bg-transparent py-[10px] px-10 text-lg text-white font-medium rounded-[6px] border-[1px] border-white hover:bg-white hover:text-brand-Orange BasicTransition'
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        defNarrow: 'h-10 rounded-md px-2',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        iconSm: 'h-9 w-9 rounded-md'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
