import { PortableTextTypeComponentProps } from '@portabletext/react';
import React from 'react';
import { CustomImageType } from '@/app/(landing)/lib/types/common';
import Image from '@/app/(landing)/components/Image';

const RichTextImage: React.FC<
  PortableTextTypeComponentProps<CustomImageType>
> = ({ value }) => {
  return (
    <Image
      src={value}
      alt={value?.alt}
      className="w-full rounded-lg my-4 h-auto object-cover"
    />
  );
};

export default RichTextImage;
