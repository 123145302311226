'use client';
import { PortableText } from '@portabletext/react';

import CreateRichTextComponent from './createRichTextComponent';
import { RichTextType } from '@/app/(landing)/lib/types/common';

export interface RichTextProps {
  block: RichTextType;
  isPricing?: boolean;
}

const RichText: React.FC<RichTextProps> = ({ block, isPricing }) => {
  const { custom_rich_text, richTextLinks } = block || {};

  const RichTextComponents = CreateRichTextComponent({
    richTextLinks,
    isPricing,
  });

  return (
    custom_rich_text && (
      <PortableText value={custom_rich_text} components={RichTextComponents} />
    )
  );
};
export default RichText;
