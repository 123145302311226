import { groq } from "next-sanity";
import { richTextFields } from "../helper/globalFields";
import { sidebarFields } from "../helper/settingsFields";


const groqQuery = groq`*[_type == "docs" && slug.current==$slug]{
_type,
_id,
"slug":slug.current,
title,
content{
${richTextFields}
},
"tableOfContents": content.custom_rich_text[style in ["h1", "h2", "h3", "h4", "h5", "h6"]],
"sidebar":${sidebarFields},
}`;

const docsSlugsQuery = groq`
*[_type == "docs" && defined(slug.current)][].slug.current`;


export const query = {
    groqQuery,
    docsSlugsQuery
};
