import React from 'react'

const SettingIcon = ({ className }: any) => {
    return (
      
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className={className}>
                <path d="M10.4368 2.11527C10.3305 1.92953 10.1792 1.77523 9.99759 1.6674C9.81598 1.55958 9.61027 1.5019 9.4005 1.5H4.5995C4.38973 1.5019 4.18402 1.55958 4.00241 1.6674C3.8208 1.77523 3.66948 1.92953 3.5632 2.11527L1.1627 6.38473C1.05611 6.57433 1 6.78939 1 7.00831C1 7.22724 1.05611 7.4423 1.1627 7.6319L3.5632 11.8847C3.66948 12.0705 3.8208 12.2248 4.00241 12.3326C4.18402 12.4404 4.38973 12.4981 4.5995 12.5H9.4005C9.61027 12.4981 9.81598 12.4404 9.99759 12.3326C10.1792 12.2248 10.3305 12.0705 10.4368 11.8847L12.8373 7.61527C12.9439 7.42567 13 7.21061 13 6.99169C13 6.77276 12.9439 6.5577 12.8373 6.3681L10.4368 2.11527ZM9.02403 7C9.02403 7.41111 8.90532 7.81299 8.68292 8.15481C8.46052 8.49664 8.14441 8.76306 7.77456 8.92038C7.40472 9.07771 6.99775 9.11887 6.60513 9.03867C6.21251 8.95847 5.85186 8.7605 5.5688 8.4698C5.28573 8.1791 5.09296 7.80873 5.01486 7.40552C4.93676 7.00231 4.97685 6.58437 5.13004 6.20455C5.28324 5.82474 5.54266 5.5001 5.87551 5.2717C6.20836 5.0433 6.59968 4.92139 7 4.92139C7.53681 4.92139 8.05163 5.14039 8.4312 5.5302C8.81078 5.92002 9.02403 6.44872 9.02403 7Z" fill="#797C85" />
            </svg>
  
    )
}

export default SettingIcon