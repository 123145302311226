import { groq } from "next-sanity";
import { linkFields } from "../linkFields";
import { imageFields } from "../imageFields";

{/* RichText  */ }
export const richTextFields = groq`
_type,
custom_rich_text,
"richTextLinks":custom_rich_text["block"==@._type].markDefs["link"==@._type]{
_type,
link{
${linkFields}
},
}
`;

{/* Button  */ }

export const buttonFields = /* groq */ `
_type,
variant,
label,
link{
${linkFields}
}
`;

{/* Plans  */ }
export const planFields = groq`
_type,
hasPopular,
name,
headline,
pricing{
${richTextFields}
},
cta{
${buttonFields}
},
contentType,
(@.contentType=="text") => { 
description{
${richTextFields}
},
},
(@.contentType=="list") => { 
contentLists[]{
isTrue,
text
},
}
`

{/* Video  */ }
export const videoFields = groq`
_type,
youtubeVideoUrl,
thumbnailImage{
${imageFields}
}
`

{/* Demo  */ }
export const demoFields = groq`
_type,
title,
description{
${richTextFields}
},
cta,
video{
${videoFields}
}
`

{/* Question And Answer  */ }

export const questionAndAnswerFields = groq`
_type,
question,
answer,
`

{/* Category  */ }
export const categoryFields=groq`
_type,
name,
slug
`

{/* Gallery  */ }
export const galleryFields=groq`
_type,
title,
slug,
description,
isPopular,
image{
${imageFields}
},
publishDate,
categories[]->{
${categoryFields}
}
`