// Boolean toggles to determine which auth types are allowed
const allowOauth = true;
const allowEmail = false;
const allowPassword = true;

// Boolean toggle to determine whether auth interface should route through server or client
// (Currently set to false because screen sometimes flickers with server redirects)
const allowServerRedirect = false;

// Check that at least one of allowPassword and allowEmail is true
if (!allowPassword && !allowEmail)
  throw new Error('At least one of allowPassword and allowEmail must be true');

export const getAuthTypes = () => {
  return { allowOauth, allowEmail, allowPassword };
};

export const getLoginViewTypes = () => {
  let loginViewTypes: string[] = [];
  if (allowEmail) {
    loginViewTypes.push('email');
  }
  if (allowPassword) {
    loginViewTypes.push('password');
  }
  return loginViewTypes;
};

export const getDefaultLoginView = (preferredLoginView: string | null) => {
  // Define the default sign in view
  let defaultView = allowPassword ? 'password' : 'email';
  if (preferredLoginView && getLoginViewTypes().includes(preferredLoginView)) {
    defaultView = preferredLoginView;
  }
  return defaultView;
};

export const getRedirectMethod = () => {
  return allowServerRedirect ? 'server' : 'client';
};
