import React, { useState } from 'react';
import { FilterType } from '.';
import SettingIcon from '../icons/SettingIcon';
import RightIcon from '../icons/RightIcon';

interface CategoryFilterProps {
  allCategories: { name: string }[];
  selectedCategories: string[];
  toggleCategory: (categoryName: string) => void;
  selectedFilter: string;
  setSelectedFilter: (filter: string) => void;
  filters?: FilterType[];
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({
  allCategories,
  selectedCategories,
  toggleCategory,
  selectedFilter,
  setSelectedFilter,
  filters
}) => {
  const [openFilterMenu, setOpenFilterMenu] = useState(false);

  const handleClick = () => {
    setOpenFilterMenu(!openFilterMenu);
  };

  return (
    <>
      <div className="md:max-w-[250px] lg:max-w-[300px] w-full md:border-r-[1px] border-brand-GrayMain md:pt-10 pb-16 lg:pb-[162px] hidden md:block">
        {/* Filter Buttons */}
        <div className="flex flex-col gap-[27px] items-start">
          {filters &&
            filters?.map((filter) => (
              <button
                key={filter?.value}
                onClick={() => setSelectedFilter(filter?.value)}
                className={`  flex gap-2.5 items-center  group    ${
                  selectedFilter === filter?.value
                    ? 'text-brand-Orange'
                    : 'text-brand-DarkGray3'
                }`}
              >
                <SettingIcon
                  className={`group-hover:[&>path]:fill-brand-Orange ${
                    selectedFilter === filter?.value
                      ? '[&>path]:fill-brand-Orange '
                      : ''
                  }`}
                />
                <span className="block text-base  font-medium leading-[140%] group-hover:text-brand-Orange font-Mono">
                  {filter?.title}
                </span>
              </button>
            ))}
        </div>

        {/* Category Buttons */}
        {allCategories && (
          <div className="mt-10">
            <div className="relative">
              <span className="text-xs text-[#A8ACB6] uppercase  leading-[140%] block w-fit relative z-[1] bg-white pr-5">{`Categories`}</span>
              <div className="h-[1px] w-full bg-brand-GrayMain absolute top-2"></div>
            </div>
            <ul className="flex flex-col gap-[27px] items-start mt-8">
              {allCategories?.map((item, index) => (
                <li key={index}>
                  <button
                    onClick={() => toggleCategory(item.name)}
                    className={`  flex gap-2.5 items-center font-Mono group    ${
                      selectedCategories.includes(item.name)
                        ? 'text-brand-Orange'
                        : 'text-brand-DarkGray3'
                    }`}
                  >
                    <SettingIcon
                      className={`group-hover:[&>path]:fill-brand-Orange ${
                        selectedCategories.includes(item.name)
                          ? '[&>path]:fill-brand-Orange '
                          : ''
                      }`}
                    />
                    <span className="block text-base  font-medium leading-[140%] group-hover:text-brand-Orange font-Mono">
                      {item.name}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="block md:hidden mb-10">
        <div
          className="flex justify-between items-center gap-5 border-b-[1px] pb-4  cursor-pointer select-none"
          onClick={handleClick}
        >
          <h3 className="text-xl font-medium font-Mono text-brand-DarkSlate">{`Content Filters`}</h3>
          <RightIcon
            className={`transition-all ease-in-out duration-500 w-4 h-4  ${openFilterMenu ? '-rotate-90' : 'rotate-90 '}`}
          />
        </div>

        <div
          className={`transition-all duration-500 ease-in-out overflow-hidden ${openFilterMenu ? 'max-h-screen' : 'max-h-0'}`}
        >
          {/* Filter Buttons */}
          <div className="flex flex-col gap-[27px] items-start pt-5">
            {filters &&
              filters?.map((filter) => (
                <button
                  key={filter?.value}
                  onClick={() => setSelectedFilter(filter?.value)}
                  className={`  flex gap-2.5 items-center  group    ${
                    selectedFilter === filter?.value
                      ? 'text-brand-Orange'
                      : 'text-brand-DarkGray3'
                  }`}
                >
                  <SettingIcon
                    className={`group-hover:[&>path]:fill-brand-Orange ${
                      selectedFilter === filter?.value
                        ? '[&>path]:fill-brand-Orange '
                        : ''
                    }`}
                  />
                  <span className="block text-base  font-medium leading-[140%] group-hover:text-brand-Orange font-Mono">
                    {filter?.title}
                  </span>
                </button>
              ))}
          </div>

          {/* Category Buttons */}
          {allCategories && (
            <div className="mt-10">
              <div className="relative">
                <span className="text-xs text-[#A8ACB6] uppercase  leading-[140%] block w-fit relative z-[1] bg-white pr-5">{`Categories`}</span>
                <div className="h-[1px] w-full bg-brand-GrayMain absolute top-2"></div>
              </div>
              <ul className="flex flex-col gap-[27px] items-start mt-8 border-b-[1px] pb-6">
                {allCategories?.map((item, index) => (
                  <li key={index}>
                    <button
                      onClick={() => toggleCategory(item.name)}
                      className={`  flex gap-2.5 items-center font-Mono group    ${
                        selectedCategories.includes(item.name)
                          ? 'text-brand-Orange'
                          : 'text-brand-DarkGray3'
                      }`}
                    >
                      <SettingIcon
                        className={`group-hover:[&>path]:fill-brand-Orange ${
                          selectedCategories.includes(item.name)
                            ? '[&>path]:fill-brand-Orange '
                            : ''
                        }`}
                      />
                      <span className="block text-base  font-medium leading-[140%] group-hover:text-brand-Orange font-Mono">
                        {item.name}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryFilter;
