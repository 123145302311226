'use client';
import React from 'react';
import { PortableTextComponents } from '@portabletext/react';

import RichTextLink, { CustomLinkProps } from '../richTextLink';
import {
  BulletList,
  RichTextItalic,
  NumberList,
  RichTextBold,
  RichTextCode,
  RichTextUnderline,
  RichTextStrikeThrough,
} from '../richTextList';
import { RichTextLinkType } from '@/app/(landing)/lib/types/common';
import RichTextImage from '../richTextImage';
import CodeBlockBox from '../codeBlockBox';
import RichTextBlock from '../richTextBlock';
import PricingRichTextBlock from '../pricingRichTextBlock';

export interface CreateRichTextComponentProps {
  richTextLinks?: RichTextLinkType[];
  isPricing?: boolean;
}

const CreateRichTextComponent = ({
  richTextLinks,
  isPricing,
}: CreateRichTextComponentProps): PortableTextComponents => {
  return {
    types: {
      customImage: RichTextImage,
      codeBlockSection: CodeBlockBox,
    },
    marks: {
      link: (props: CustomLinkProps) => (
        <RichTextLink {...props} richTextLinks={richTextLinks} />
      ),
      em: RichTextItalic,
      strong: RichTextBold,
      code: RichTextCode,
      underline: RichTextUnderline,
      'strike-through': RichTextStrikeThrough,
    },
    list: {
      bullet: BulletList,
      number: NumberList,
    },
    block: isPricing ? PricingRichTextBlock : RichTextBlock,
  };
};

export default CreateRichTextComponent;
