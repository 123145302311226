import React from "react";

function MobileToggle({ className }: { className: string }) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34 33" fill="none" className={className}>
                <path d="M4.32031 26.125V23.375H29.0703V26.125H4.32031ZM4.32031 17.875V15.125H29.0703V17.875H4.32031ZM4.32031 9.625V6.875H29.0703V9.625H4.32031Z" fill="black" />
            </svg>
        </div>
    );
}

export default MobileToggle;